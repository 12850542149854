// useAxios.js
import { getApiClient } from "./apiClient";

// Custom hook to set up Axios interceptors
const useAxios = () => {
  const apiClient = getApiClient()
  return apiClient;
};

export default useAxios;

import React from "react";
import { NavLink } from "react-router-dom";

interface ButtonProps {
  title?: string;
  size?: "xs" | "sm" | "md" | "lg" | "xl",
  navlink?: string;
  className?: string;
  preventDefault?: boolean;
  color?: string; 
  onClick?: () => void;
  type?: "outlined" | "contained";
  children: React.ReactNode; // children can be any valid React node
}

const Button: React.FC<ButtonProps> = (props) => {
  const { size = "md", color, type, children, preventDefault } = {
    ...{
      type: "contained",
    },
    ...props,
  };

  const handleClick = (e: any) => {
    if (preventDefault) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (props.onClick) {
      props.onClick();
    }
  };

  const render = (
    <button
      title={props.title}
      onClick={handleClick}
      className={[
        "group relative rounded inline-block cursor-pointer select-none",
        !color && type !== "outlined" ? "text-[#091028]" : "",
        !color && type === "outlined" ? "text-[#ff4200]" : "",
        color && type !== "outlined" ? "text-[#091028]" : "",
        color && type === "outlined" ? `text-${color}-500` : "",
        size === "sm" ? "m-1 text-xs py-1 px-2" : "",
        size === "md" ? "m-1 text-sm py-1.5 px-4" : "",
        props.className,
        "font-bold text-opacity-60 hover:text-opacity-100 transition-all",
      ].join(" ")}
    >
      {!color && <div className="blurs absolute top-0.5 left-0.5 right-0.5 bottom-0.5">
        <div className="opacity-100 group-hover:opacity-0 absolute group-active:-inset-1.25 group-active:opacity-50 -inset-1 rounded  opacity-25 blur-sm from-[#ff4200] to-[#ba00b6] bg-gradient-to-tr"></div>
        <div className="opacity-0 group-hover:opacity-100 absolute group-active:-inset-1.25 group-active:opacity-50 -inset-1 rounded  opacity-25 blur-sm from-[#ff4200] to-[#ba00b6] bg-gradient-to-bl"></div>
      </div>}
      {color && <div className="blurs absolute top-0.5 left-0.5 right-0.5 bottom-0.5">
        <div className={`opacity-100 group-hover:opacity-0 absolute group-active:-inset-1.25 group-active:opacity-50 -inset-1 rounded  opacity-25 blur-sm from-${color}-500 to-${color}-700 bg-gradient-to-tr`}></div>
        <div className={`opacity-0 group-hover:opacity-100 absolute group-active:-inset-1.25 group-active:opacity-50 -inset-1 rounded  opacity-25 blur-sm from-${color}-500 to-${color}-700 bg-gradient-to-bl`}></div>
      </div>}      
      {!color && <div className="backgrounds absolute left-0 top-0 right-0 bottom-0 overflow-hidden rounded">
        <div className="opacity-100 group-hover:opacity-0 absolute left-0 top-0 right-0 bottom-0 transition-all from-[#ff4200] to-[#ba00b6] bg-gradient-to-tr"></div>
        <div className="opacity-0 group-hover:opacity-100 absolute left-0 top-0 right-0 bottom-0 transition-all from-[#ff4200] to-[#ba00b6] bg-gradient-to-bl"></div>
      </div>}
      {color && <div className="backgrounds absolute left-0 top-0 right-0 bottom-0 overflow-hidden rounded">
        <div className={`opacity-100 group-hover:opacity-0 absolute left-0 top-0 right-0 bottom-0 transition-all from-${color}-500 to-${color}-700 bg-gradient-to-tr`}></div>
        <div className={`opacity-0 group-hover:opacity-100 absolute left-0 top-0 right-0 bottom-0 transition-all from-${color}-500 to-${color}-700 bg-gradient-to-bl`}></div>
      </div>}      
      {type === "outlined" && (
        <div className="absolute top-0.5 left-0.5 right-0.5 bottom-0.5 bg-[#091028] rounded"></div>
      )}

      <div className="relative">{children}</div>
      <div className="hidden">
      <div className="from-green-500 to-green-700 text-green-500"/>
      <div className="from-yellow-500 to-yellow-700 text-yellow-500"/>
      <div className="from-blue-500 to-blue-700 text-blue-500"/>
      <div className="from-purple-500 to-purple-700 text-purple-500"/>
      <div className="from-red-500 to-red-700 text-red-500"/>
      </div>
    </button>
  );

  if (props.navlink !== undefined) {
    return <NavLink to={props.navlink}>{render}</NavLink>;
  }

  return render;
};

export default Button;

import React from "react";
import Topbar from "./Page/Topbar";
import Helmet from "../elements/helmet/Helmet";
import Footer from "./Page/Footer";
interface PageProps {
  className?: string;
  full?: boolean;
  children: React.ReactNode; // children can be any valid React node
}

const Page: React.FC<PageProps> = ({ children, full }) => {
  return (
    <>
    <Helmet/>
    <div className="h-screen text-gray-300">
      <div className="fixed top-0 left-0 h-full w-full bg-gray-900">
        {/* <div className="absolute top-0 left-0 h-full w-full bg-black bg-opacity-40"></div> */}
        <div className="absolute top-0 left-0 h-full w-full from-10% from-[#ff4200] via-[#ba00b6] via-70% bg-gradient-to-tr mix-blend-overlay"></div>
      </div>
      <div className="flex min-h-full flex-col">
        <Topbar></Topbar>
        {full ? (
          <main className="relative flex-1 flex w-full mx-auto p-y2 overflow-y-auto">
            {children}
          </main>
        ) : (
          <main className="relative   flex-1 flex items-center container mx-auto p-2 overflow-y-auto">
            {children}
          </main>
        )}

        <Footer/>
      </div>
    </div>
    </>
  );
};

export default Page;

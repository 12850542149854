import React from "react";
import Page from "../skeletons/Page";
import Highlight from "../elements/atoms/Highlight";
import Button from "../elements/atoms/Button";
import Block from "../elements/atoms/Block";

function Home() {
  return (
    <Page>
      <div className="w-full text-center pt-32">
        <div className="py-2 px-8">
          <Highlight className="font-black text-center text-3xl md:text-5xl">
            Unleash the power !
          </Highlight>
          <div className="font-medium md:text-xl">
            Massive GPU Power for Fast, Scalable Hash Cracking
          </div>
        </div>

        <Button
          navlink="https://app.hashrepublic.net"
          className="scale-150 mt-16 hover:scale-[175%] transition-all !text-opacity-100"
        >
          Get Started !
        </Button>
        <div className="text-sm p-2 opacity-70">
          Specific needs ? &nbsp;
          <a className="inline-block underline opacity-50 hover:opacity-70 active:opacity-100" href="mailto:contact@hashrepublic.net">contact us</a>
        </div>
        <div className="h-20"></div>

        <Block
          title="Maximum Power"
          visual={"/assets/gpus.webp"}
          className="text-center w-full"
          avoidLazy
        >
          <div className="text text-gray-500">
            Parallelize hundreds of RTX 4090
          </div>
          <p className="max-w-[90vw] m-auto mt-4 text-gray-400 text-sm">
            Effortlessly leverage the combined strength of multiple GPUs,
            streamlining the distribution of Hashcat tasks for maximum
            efficiency. <br />
            Picture deploying hundreds of RTX 4090 GPUs on a single project,
            slashing hash reversal times like never before. <br />
            With access to exclusive, optimized wordlists and rulesets, you can
            elevate your cracking capabilities to new heights. <br />
            Step into the future of hash reversing.
          </p>
        </Block>


        <Block
          title="All Hash Modes Supported"
          visual={"/assets/hashmodes.webp"}
          orientation="right-to-left"
          className="text-center w-full"
        >
          <div className="text text-gray-500">
          Comprehensive Hash Support for Every Algorithm You Need
          </div>
          <p className="max-w-[90vw] m-auto mt-4 text-gray-400 text-sm">
            We support every hash mode available in Hashcat 6.2.6, with over 500+ algorithms at your disposal. <br/>
            Whether you're dealing with simple or complex hashes, our platform ensures compatibility with the latest standards, giving you the versatility to tackle any project.
          </p>
        </Block>

        <Block
          orientation=""
          title="Flexible Pricing"
          visual={"/assets/pricing.webp"}
          className="text-center w-full"
        >
          <div className="text text-gray-500">
            Flexible Pricing for Every Cracking Challenge
          </div>
          <p className="max-w-[90vw] m-auto mt-4 text-gray-400 text-sm">
            Whether you're aiming to shatter world records with immense cracking
            power or simply looking for an efficient way to reverse your hashes,
            we have a pricing plan tailored to your needs. <br />
            Choose anywhere from 6x RTX 4090 GPUs per job to a staggering 1400x
            RTX 4090. <br />
          </p>
        </Block>
        {/* <Block
          orientation="right-to-left"
          title="Custom Wordlists & Rulesets"
          visual={"/assets/wordlists.webp"}
          className="text-center w-full"
        >
          <div className="text text-gray-500">
            Handcrafted professional wordlists and rulesets
          </div>
          <p className="max-w-[90vw] m-auto mt-4 text-gray-400 text-sm">
            With years of expertise in hash cracking, we've meticulously crafted
            custom wordlists and rulesets, refining them with our unique
            techniques and skills. <br />
            Experience professional-grade tools designed to accelerate your
            success and step into a new era of advanced hash cracking.
          </p>
        </Block> */}
      </div>
    </Page>
  );
}

export default Home;

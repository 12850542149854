import React, { useEffect, useRef, useState } from 'react';

interface LazyImageProps {
  src: string;
  alt: string;
  avoidLazy?: boolean;
  width?: string; 
  height?: string;
  className?: string;
  placeholder?: string; // Optional placeholder while loading
}

const LazyImage: React.FC<LazyImageProps> = ({ src, alt, avoidLazy, width, height, className, placeholder }) => {
  const imgRef = useRef<HTMLImageElement | null>(null);
  const [isIntersecting, setIsIntersecting] = useState(false);

  useEffect(() => {
    const imgElement = imgRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsIntersecting(true);
          if (imgElement) observer.unobserve(imgElement);
        }
      },
      {
        root: null, // Use the viewport as the root
        rootMargin: '100px',
        threshold: 0.1, // Trigger when 10% of the image is visible
      }
    );

    if (imgElement) observer.observe(imgElement);

    return () => {
      if (imgElement) observer.unobserve(imgElement);
    };
  }, []);

  return (
    <img
      className={className}
      ref={imgRef}
      width={width}
      height={height}
      src={avoidLazy || isIntersecting ? src : placeholder}
      alt={alt}
      loading={avoidLazy ? "eager" : "lazy"}
    />
  );
};

export default LazyImage;

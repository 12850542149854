import React from "react";
import Page from "../skeletons/Page";
import Highlight from "../elements/atoms/Highlight";
import Benchmark from "../elements/atoms/benchmark/Benchmark";
import { RTX4090 } from "../../benchmarks/RTX4090";

function Benchmarks() {
  return (
    <Page>
      <div className="w-full text-center pt-16">
        <div className="py-2 px-8">
          <Highlight className="font-black text-center text-3xl md:text-5xl">
            Benchmarks
          </Highlight>
          <div className="font-medium md:text-xl">
            <div className="text-sm text-gray-500">
           Because we all want to have correct estimations on our attack before run them. <br/>
           And because the price of an attack highly depends on the speed, it's good to have accurate estimations.
           </div>
          </div>
        </div>

 
        <div className="h-10"></div>

        <div className="p-2">
          <div className="bg-gray-900 text-gray-600 p-4 text-left rounded shadow">
            <div className="p-4 mb-4">
            <div className="text-center uppercase text-xl font-bold text-gray-400">
              RTX 4090
            </div>
            <div className="text-center text-xs italic">
              Runpod.io single RTX4090 instance using our custom <Highlight>hashrepublic/hashcat-cuda:12.1.1</Highlight> docker image.
            </div>
            </div>
            <div className="p-4">
              <Benchmark data={RTX4090}/>
            </div>
          </div>
        </div>

      </div>
    </Page>
  );
}

export default Benchmarks;

import React from "react";
import Page from "../skeletons/Page";
import Highlight from "../elements/atoms/Highlight";
import Accordion from "../elements/atoms/Accordion";
import AccordionElement from "../elements/atoms/AccordionElement";

function FAQ() {
  return (
    <Page>
      <div className="w-full text-center pt-16">
        <div className="py-2 px-8">
          <Highlight className="font-black text-center text-3xl md:text-5xl">
            Frequently Asked Questions
          </Highlight>
          <div className="font-medium md:text-xl">
            Because Even Hackers Have Questions...
          </div>
        </div>

 
        <div className="h-10"></div>

        <div className="p-2">
          <Accordion className="w-full">
          <AccordionElement title="What is HashRepublic?">
              Hash Republic is a platform dedicated to hash cracking, where cybersecurity enthusiasts, professionals, and hobbyists can engage in challenges to reverse various cryptographic hashes.
            </AccordionElement>
            <AccordionElement title="How does it work?">
              At Hash Republic, we distribute computational jobs across high-performance, multi-GPU machines that we access through reputable GPU cloud providers such as Vast.ai and Runpod.io. This approach enables us to harness significant processing power for large-scale hash-cracking tasks, ensuring that our users benefit from efficient and scalable performance without the need for dedicated hardware. By leveraging these resources, we deliver an optimized and cost-effective solution for handling intensive cryptographic workloads.
            </AccordionElement>     
            <AccordionElement title="What is the hardware limit?">
              At Hash Republic, hardware limitations are <Highlight>virtually nonexistent</Highlight>. The primary constraint is the budget you're willing to allocate for your hash-cracking tasks and the availability of hardware from our GPU providers. With access to a vast pool of high-performance GPUs, the chances of exhausting all available resources are minimal. This ensures you have the flexibility to scale your workloads as needed, while maintaining control over your costs.
            </AccordionElement>                                    
            <AccordionElement title="What kind of hashes can I reverse?">
              At Hash Republic, you can reverse <Highlight>any hash supported by Hashcat</Highlight>, the core engine that powers our platform. This includes popular algorithms like MD5 and Bcrypt, as well as more specialized or exotic hashes such as MongoDB and Django. In addition, we are actively developing our own proprietary engine to expand support for cracking hashes that Hashcat does not currently cover, such as Shiro2. This initiative is part of our preparation for future cracking contests, where participants will be able to tackle even more diverse and complex challenges.
              </AccordionElement>
            <AccordionElement title="How are the cost calculated?">
              Our cost calculation process is straightforward and fully automated. We estimate the time required to complete your specified job, multiply it by the cost of renting the selected number of GPUs for that duration, and then provide you with a detailed quote. You can choose to accept or decline the quote at your convenience. This streamlined approach ensures that even with the cost estimation process, your job initiation remains fast and efficient, keeping you focused on cracking hashes without unnecessary delays.
            </AccordionElement>                                  
          </Accordion>
        </div>

      </div>
    </Page>
  );
}

export default FAQ;

// src/components/Logo.tsx
import React from "react";


interface LogoProps {
  color: "white" | "gradient";
  className?: React.ReactNode;
 }

const Logo: React.FC<LogoProps> = ({className, color}) => {
  return (
    <svg
      className={[className].join(" ")}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 800 800"
    >
      {color === "gradient" && (<defs>
        <linearGradient id="gradient" x1="0%" y1="100%" x2="100%" y2="0%">
          <stop offset="0%" style={{ stopColor: '#ff4200', stopOpacity: 1 }} />
          <stop offset="100%" style={{ stopColor: '#ba00b6', stopOpacity: 1 }} />
        </linearGradient>
      </defs>)}
      <g transform="translate(20,120)" fill={color === "gradient" ? "url(#gradient)" : "#fff"} >
        <path
          className="bg-red-600"
          d="M26.44.34h59.28c8.21,0,21.91-1.22,27.84.96,8.03,2.95,14.22,8.93,17.52,16.56,2.51,5.8,2.34,23.28,1.44,30.96.08,3.84.16,7.68.24,11.52-.08,5.36-.16,10.72-.24,16.08,0,10.93-.55,22.07,1.68,30.96,2.88,11.52,11.48,20,20.4,25.68,10.4,6.63,27.3,5.76,44.4,5.76h97.44c43.45,6.93,72.36,22.85,94.32,50.88,7.83,10,15.77,19.52,20.88,32.4,3,7.56,7.33,15.98,9.6,24.48,1.54,5.74,1.75,10.17,3.12,16.56.85,3.95,2.6,9.29,1.92,13.92.16,59.27.32,118.57.48,177.84v20.16c-.26,19.72,1.54,32.89-10.8,42-11.13,8.22-25.73,7.2-45.12,7.2h-57.84c-8.63-2.78-18.44-9.01-21.6-17.28-5.04-13.2-2.64-36.59-2.64-54v-109.44c0-19.78,1.73-43.35-4.8-56.4-5.79-11.57-18.74-20.89-33.36-23.52-11.2-.16-22.4-.32-33.6-.48-13.52.08-27.04.16-40.56.24-22.77,3.52-33.41,11.6-41.76,29.28-3.88,8.2-1.22,18.89-2.88,29.76v11.52c-1.97,12.9,0,27.24,0,40.08.08,25.04.16,50.08.24,75.12.08,6.64.16,13.28.24,19.92,0,14.69,1.61,31.78-4.32,41.04-12.37,19.32-43.69,14.16-74.4,14.16-15.28,0-29.85.75-38.88-5.28C-1.46,508.21.04,491.45.04,464.98V48.1c0-13.7-.42-27.63,5.28-35.76C8.29,8.1,14.14,3.05,19.48,1.3c2.65-.87,4.91.27,6.96-.96Z"
        />
        <path
          className="bg-red-600"
          d="M451,25.78h118.32c18.96.08,37.92.16,56.88.24,14.04,2.3,26.51,3.82,38.64,7.44,43.63,13.03,71.88,47.37,86.88,89.52,7.27,20.44,5.76,45.33,5.76,71.52v42.72c-.16,7.04-.32,14.08-.48,21.12-1.62,7.88-2.54,18.06-5.28,25.44-16.37,44.11-42.62,74.68-89.04,88.56-20.93,6.26-51.44,4.08-78,4.08h-73.92c-7.44,0-24.86,1.6-29.28-.96-.16-28.16-.32-56.32-.48-84.48-.6-3.96-.32-22.48.72-23.28,2.84-2.18,12.88-.72,17.28-.72,15.6-.08,31.2-.16,46.8-.24,14.01,0,30.68,1.82,43.44-.72,21.01-4.19,33.91-16.9,40.08-36,2.79-8.63,1.2-20.6,1.2-31.2-.16-8.8-.32-17.6-.48-26.4-4.2-21.4-18.79-33.27-40.8-36.96-16.4-.08-32.8-.16-49.2-.24h-126.96c-4.72-.16-9.44-.32-14.16-.48-10.9-2.69-17.86-11.76-23.76-19.68-3.84-4.32-7.68-8.64-11.52-12.96-4.91-7.1-10.51-13.58-16.08-20.16-5.49-6.49-13.64-12.83-17.28-20.88-5.63-12.46-1.12-23.27,7.44-29.52,7.76-5.66,20.28-5.52,33.84-5.52,18.08,0,35.51,0,53.52,0,8.64-.08,17.28-.16,25.92-.24Z"
        />
        <path
          className="bg-red-600"
          d="M604.6,409.3c16.88.08,33.76.16,50.64.24,8.38,0,21.51-.91,28.32,1.44,9.99,3.44,16.79,12.33,22.8,19.92,10.63,13.41,22.46,25.87,33.12,39.36,8.76,11.08,21.03,20.88,27.36,34.08,2.27,4.73-3.36,13.67-4.8,15.36-4.84,5.65-15.36,4.56-25.92,4.56h-70.8c-12.56-2.46-21.27-12.15-28.08-20.64-3.76-4.08-7.52-8.16-11.28-12.24-11.18-14.32-23.01-28.66-35.52-41.76-4.13-4.32-22.02-20.54-18.72-29.28,3.6-9.52,10.15-10.8,23.52-10.8,3.12-.08,6.24-.16,9.36-.24Z"
        />
      </g>
    </svg>
  );
};

export default Logo;

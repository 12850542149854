import axios from "axios";

export const getApiBaseUrl = () => {
  return  process.env.REACT_APP_ENV === "production"
  ? "https://api.hashrepublic.net"
  : "http://localhost:8080" 
}

export const getApiClient = () => {
    return axios.create({
        baseURL: getApiBaseUrl(),
        // You can also set default headers if needed
        headers: {
          "Content-Type": "application/json",
          // Add other default headers here
        },
      });
}

import React, { useCallback, useState } from "react";

interface AccordionElementProps {
  className?: string;
  title: string;
  children: React.ReactNode;
}

const AccordionElement: React.FC<AccordionElementProps> = (props) => {
  const {  title, children, className } = props;
  const [isToggled, setToggle] = useState<boolean>(false)

  const toggleAccordion = useCallback(() => {
    setToggle(!isToggled)
  }, [isToggled])

  return (
    <div className={[
        "bg-gray-900 border-b border-black w-full text-left inline-flex flex-col",
        className
    ].join(' ')}>
      <div className="py-4 px-8 text-gray-400 bg-black font-bold bg-opacity-40" onClick={toggleAccordion}>
        {title}
      </div>
      <div className={[
        "p-4 text-gray-600 font-mono text-sm",
        (isToggled ? "visible" : "hidden")
      ].join(" ")}>
        {children}
      </div>
    </div>
  );
};

export default AccordionElement;

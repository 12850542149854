// ToastContext.tsx
import React, { createContext, useState, useContext, useCallback, ReactNode } from 'react';
import Toast from './Toast';
import { v4 as uuidv4 } from 'uuid'; // Import uuid

type ToastType = 'info' | 'success' | 'error';

interface ToastItem {
  id: string;
  message: string;
  type: ToastType;
}

interface ToastContextProps {
  addToast: (message: string, type?: ToastType, delay?: number) => string;
  delToast: (id: string) => void; 
}

const ToastContext = createContext<ToastContextProps | undefined>(undefined);

export const useToast = (): ToastContextProps => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

export const ToastProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [toasts, setToasts] = useState<ToastItem[]>([]);

  const addToast = useCallback((message: string, type: ToastType = 'info', delay: number = 3000) => {
    const id = uuidv4();
    setToasts((prevToasts) => [...prevToasts, { id, message, type }]);
    if(delay > 0){
      setTimeout(() => {
        setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
      }, delay); 
    }
    return id
  }, []);

  const delToast = useCallback((id: string) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  }, []);

  return (
    <ToastContext.Provider value={{ addToast, delToast }}>
      {children}
      <div className="fixed z-50 font-sans bottom-6 left-6 right-6 pointer-events-none">
        {[...toasts].map((toast) => (
          <Toast key={toast.id} id={toast.id} type={toast.type}>
            {toast.message}
            </Toast>
        ))}
      </div>
    </ToastContext.Provider>
  );
};

import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { routes } from "../../../router";

function Router() {
  const router = createBrowserRouter(routes.map((it) => {
    return {
      path: it.path,
      element: it.element
    }
  }));

  return (
    <React.Fragment>
      <RouterProvider router={router} />
    </React.Fragment>
  );
}

export default Router;

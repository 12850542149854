import Home from "./components/pages/Home";
import Challenge from "./components/pages/Challenge";
import NotFound from "./components/pages/NotFound";
import { Navigate } from "react-router-dom";
import FAQ from "./components/pages/FAQ";
import Benchmarks from "./components/pages/Benchmarks";

export const routes = [
  {
    path: "/",
    title: "Hash Republic",
    description:
      "Hash Republic allows users to rent powerful GPUs for Hashcat jobs. Join us for fast, efficient, and reliable hash-cracking services.",
    element: <Home />,
  },
  {
    path: "/challenge",
    title: "Hash Republic - Challenge",
    description:
      "Take on hash reversal challenges, crack the code, and win prizes. Test your skills at Hash Republic!",
    element: <Challenge />,
  },
  {
    path: "/faq",
    title: "Hash Republic - FAQ",
    description:
      "Want to know more about Hash Republic? Read the FAQ!",
    element: <FAQ />,
  },  
  {
    path: "/benchmarks",
    title: "Hash Republic - Benchmarks",
    description:
      "Because you want the maximum accuracy when hash-cracking!",
    element: <Benchmarks />,
  },  
 
  {
    path: "/404",
    title: "Hash Republic - Not Found",
    noIndex: true,
    element: <NotFound />,
  },
  {
    path: "*",
    noIndex: true,
    element: <Navigate to={"/404"} />,
  },
];

import React from "react";
import { Helmet as ReactHelmet } from "react-helmet-async";
import { useMatch } from "react-router-dom";
import { routes } from "../../../router";

interface HelmetProps {
  children?: React.ReactNode; // children can be any valid React node
}

const Helmet: React.FC<HelmetProps> = ({ children }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const matchedRoute:any = routes.find(route => useMatch(route.path));

  return (
    <ReactHelmet>
      <title>{matchedRoute.title}</title>
      {matchedRoute.noIndex && <meta name="robots" content="noindex" />}
      {matchedRoute.description && <meta name="description" content={matchedRoute.description} />}
      {children}
    </ReactHelmet>
  );
};

export default Helmet;

/* eslint-disable no-template-curly-in-string */
/* eslint-disable jsx-a11y/img-redundant-alt */
import Page from "../skeletons/Page";
import Highlight from "../elements/atoms/Highlight";
import { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { FormProvider } from "../elements/atoms/form/FormContext";
import Form from "../elements/atoms/form/Form";
import InputText from "../elements/atoms/form/input/InputText";
import Button from "../elements/atoms/Button";
import Panel from "../elements/atoms/Panel";
import useAxios from "../../api/useAxios";
import { getApiBaseUrl } from "../../api/apiClient";
import { useToast } from "../elements/atoms/toast/ToastContext";
import LazyImage from "../elements/atoms/LazyImage";
import Placeholder from "../elements/atoms/Placeholder";

function Challenge() {
  const apiBaseUrl = getApiBaseUrl();
  const { addToast } = useToast();
  const [challenges, setChallenges] = useState<any[]>([]);
  const [selectedChallenge, setSelectedChallenge] = useState<any>();
  const axios = useAxios();

  const loadChallenges = useCallback(async () => {
    // await new Promise((r) => setTimeout(r, 100000));
    const resp = await axios.get("/v1/challenge/list");
    setChallenges(resp.data);
    setSelectedChallenge(resp.data[0]);
  }, [axios]);

  useEffect(() => {
    if (challenges.length !== 0) {
      return;
    }
    loadChallenges();
  }, [axios, challenges.length, loadChallenges]);

  const validationSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
    plaintext: yup.string().required("Plaintext is required"),
  });

  const onSubmit = async (values: any) => {
    try {
      await axios.post(`/v1/challenge/${selectedChallenge.id}/resolve`, values);
      addToast(
        "SUCESS! You will soon receive an email for formalities!",
        "success"
      );
      loadChallenges();
    } catch (error: any) {
      addToast(error.response.data.error, "error");
    }
  };

  return (
    <Page>
      <div className="flex flex-col w-full">
        {/* BACKGROUND */}
        <div className="">
          {!!challenges.length &&
            challenges.map((challenge) => {
              return (
                <div
                  key={`bg-${challenge.hash}`}
                  style={{ zIndex: 0 }}
                  className={[
                    "fixed pointer-events-none top-0 bottom-0 right-0 left-0 blur-xl transition-all",
                    selectedChallenge &&
                    selectedChallenge.hash === challenge.hash
                      ? "opacity-30"
                      : "opacity-0",
                  ].join(" ")}
                >
                  <LazyImage
                    src={`${apiBaseUrl}/v1/challenge/${challenge.id}/image`}
                    alt="challenge image"
                    className="w-full h-full"
                  />
                </div>
              );
            })}
        </div>
        <div className="relative">
          <div className=" ">
            <div className="text-center mt-10">
              {challenges.length === 0 && (
                <>
                  <Placeholder className="inline-flex p-2 relative !h-60 !w-40 scale-90 rounded shadow-xl inline-flex" />
                  <Placeholder className="inline-flex p-2 relative !h-60 !w-40 rounded shadow-xl inline-flex" />
                  <Placeholder className="inline-flex p-2 relative !h-60 !w-40 scale-90 rounded shadow-xl inline-flex" />
                </>
              )}
              {challenges.map((challenge) => {
                return (
                  <div
                    key={`carousel-${challenge.hash}`}
                    onClick={() => setSelectedChallenge(challenge)}
                    className={[
                      "from-[#000] via-[#000]  to-[#ba00b600] border border-black bg-gradient-to-tr cursor-pointer relative h-60 w-40 rounded shadow-xl inline-flex items-center p-2   transition-all",
                      selectedChallenge &&
                      selectedChallenge.hash === challenge.hash
                        ? "scale-100 opacity-100"
                        : "opacity-60 hover:opacity-80 scale-90 hover:scale-100",
                    ].join(" ")}
                  >
                    <LazyImage
                      src={`${apiBaseUrl}/v1/challenge/${challenge.id}/image`}
                      alt="challenge image"
                      className="w-full "
                    />
                    {challenge.status === "ACTIVE" && (
                      <div className="absolute opacity-60 bottom-0 left-0 right-0 p-1 font-bold text-sm text-center bg-green-600 rounded-b">
                        ACTIVE
                      </div>
                    )}
                    {challenge.status === "COMPLETED" && (
                      <div className="absolute opacity-60 bottom-0 left-0 right-0 p-1 font-bold text-sm text-center bg-blue-600 rounded-b">
                        COMPLETED
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>

          <div className="text-center p-10">
            <Highlight className="font-black text-2xl md:text-5xl">
              {selectedChallenge ? (
                selectedChallenge.label
              ) : (
                <Placeholder className="w-[60vw]" />
              )}
            </Highlight>
            <div className="font-medium text-gray-400 md:text-xl">
              {selectedChallenge ? (
                selectedChallenge.description
              ) : (
                <Placeholder className="w-[65vw]" />
              )}
            </div>
            <div className="p-5">
              {selectedChallenge ? (
                <>
                  <span className="text-gray-600 italic">
                    Hash to reverse :{" "}
                  </span>{" "}
                  <br />
                  <span className="font-mono text-xs">
                    {selectedChallenge.hash}
                  </span>
                </>
              ) : (
                <Placeholder className="w-[40vw]" />
              )}
            </div>

            {selectedChallenge && selectedChallenge.status === "ACTIVE" && (
              <div className="">
                <FormProvider
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  <Form>
                    <div className="my-4">
                      <div className="inline-block max-w-[400px]">
                        <Panel>
                          <InputText label="Email" name="email" />
                          <InputText
                            label="Plaintext (flag format: '${...}')"
                            name="plaintext"
                          />

                          <Button type="outlined" className="mt-4">
                            {" "}
                            Submit{" "}
                          </Button>
                        </Panel>
                      </div>
                    </div>
                  </Form>
                </FormProvider>
              </div>
            )}
            {selectedChallenge && selectedChallenge.status === "COMPLETED" && (
              <div>
                {selectedChallenge.resolver === "" && (
                  <div className="text-xl">Completed</div>
                )}
                {selectedChallenge.resolver !== "" && (
                  <div className="text-xl">
                    <span className="italic text-gray-600">Completed by</span>{" "}
                    &nbsp;
                    <span className="font-bold">
                      {selectedChallenge.resolver}{" "}
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>

          {selectedChallenge && (
            <>
              <div className="text-3xl md:text-5xl text-gray-400 text-center">
                What to win ?
              </div>
              <div className="text-center">
                <Highlight className="inline-block m-5 text-center">
                  <div className="">
                    <span className="">$</span>
                    <span className="text-5xl">100</span>
                  </div>
                  <div className="">Cash Prize</div>
                </Highlight>
                <Highlight className="inline-block m-5 text-center">
                  <div className="">
                    <span className="text-5xl">Hoodie</span>
                  </div>
                  <div className="">of the next challenge</div>
                </Highlight>
              </div>
            </>
          )}
        </div>
      </div>
    </Page>
  );
}

export default Challenge;

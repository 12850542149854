import React from "react";
interface BenchmarkProps {
  data: any
}

const Benchmark: React.FC<BenchmarkProps> = ({ data }) => {

 

  return (
     <div className="flex">

        <table className="w-full table-fixed">
          <thead>
          <th> Mode </th>
          <th> Regular </th>
          <th> Optimized "-O" </th>
          <th> Perf Ratio </th>
          </thead>
          <tbody>
            {Object.keys(data).map((mode) => {
              const specs = data[mode]
              return <tr className="border-b border-b-gray-800" key={mode}>
                <th className="pr-1">
                  <div>
                  {mode}
                  </div>
                  <div className="text-xs text-gray-700 overflow-hidden text-ellipsis">
                  {specs.name}
                  </div>
                  </th>
                <td>
                  <div className="p-1 flex flex-col">
                    <div>
                      {specs.regular.speed}
                    </div>
                    <div className="text-xs text-gray-700">
                      {specs.regular.minLen} - {specs.regular.maxLen} candidates lengths
                    </div>
                  </div>
                </td>
                <td>
                <div className="p-1 flex flex-col">
                    <div>
                      {specs.optimized.speed}
                    </div>
                    <div className="text-xs text-gray-700">
                      {specs.optimized.minLen} - {specs.optimized.maxLen} candidates lengths
                    </div>
                  </div>
                </td>
                <td>
                  {specs.ratio}
                </td>
              </tr>

            })}
          </tbody>
        </table>  
     </div>
  );
}

export default Benchmark;

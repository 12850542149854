// MatomoProvider.tsx
import React, { createContext, useContext, useEffect, useState } from "react";


interface MatomoContextType {
    modes: any[];
    getMode: (mode: string) => any,
    modeAsString: (mode: string) => string,
    refreshModes: () => Promise<void>;
  }
  
  // Create the context
const MatomoContext = createContext<MatomoContextType | undefined>(undefined);

export const MatomoProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [initialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (initialized) {
      return;
    }
    setInitialized(true);
    if (!window.location.href.includes('hashrepublic.net')) {
        return;
    }
    var u = "https://matomo.hashrepublic.net/";
    (window as any)._paq = (window as any)._paq || [];
    (window as any)._paq.push(["trackPageView"]);
    (window as any)._paq.push(["enableLinkTracking"]);
    (window as any)._paq.push(["setTrackerUrl", u + "matomo.php"]);
    (window as any)._paq.push(["setSiteId", "1"]);

    var d = document;
    var g = d.createElement("script");
    var s = d.getElementsByTagName("script")[0];
    g.async = true;
    g.src = u + "matomo.js";
    s.parentNode!.insertBefore(g, s);
  }, [initialized, setInitialized]);

  return <div className="matomo-provider">{children}</div>;
};

export const useMatomo = () => {
  const context = useContext(MatomoContext);
  if (!context) {
    throw new Error("useMatomo must be used within a MatomoProvider");
  }
  return context;
};

// src/components/Footer.tsx
import React from "react";

const Footer: React.FC = () => {
  return (
    <footer className="relative text-gray-600  p-6 mt-12">
    <div className="container flex items-center w-full mx-auto">
      <p className="w-full text-center">&copy; 2024 Hash Republic</p>
    </div>
  </footer>
  );
};

export default Footer;

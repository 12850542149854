import React from "react";
import Highlight from "./Highlight";
import LazyImage from "./LazyImage";
interface BlockProps {
  title: string; 
  visual: string;
  avoidLazy?: boolean;
  orientation?: string;
  className?: string;
  children: React.ReactNode; // children can be any valid React node
}

const Block: React.FC<BlockProps> = ({ title, orientation, visual, avoidLazy, children }) => {

  let flexOrientation = "lg:flex-row"
  let bgPosition = "lg:-right-32"
  let textPadding = "lg:px-16"
  if(orientation === "right-to-left"){
    flexOrientation = "lg:flex-row-reverse"
    bgPosition = "lg:-left-32"
     textPadding = "lg:px-16"
  }

  return (
     <div className="p-4 md:p-10 lg:p-20 m-10 mb-20 lg:mb-10 relative">
      <div className={["relative flex flex-col-reverse", flexOrientation].join(" ")}>
      <div className="text text-left flex items-center relative flex-1">
        <div className={["absolute rounded shadow -top-32 lg:-top-8 -left-8 -bottom-8 -right-8 bg-black  opacity-50 blur-xl", bgPosition].join(" ")}></div>        
        <div className={["relative pt-16 lg:pt-0", textPadding].join(" ")}>
        <Highlight className="text-3xl mx-2">{title}</Highlight>
        <div className="">
          { children }
        </div>
        </div>
      </div>
      <div className="relative text-center">
        <LazyImage avoidLazy={avoidLazy} src={visual} alt={visual} width="208" height="208" className="inline rounded shadow-xl border border-black"/>
      </div>
      </div>
      
     </div>
  );
}

export default Block;

import React from "react";

interface AccordionProps {
  className?: string;
  children: React.ReactNode;
}

const Accordion: React.FC<AccordionProps> = (props) => {
  const { children, className } = props;

  return (
    <div className={[
        "rounded overflow-hidden shadow border border-black",
        className
    ].join(' ')}>
      {children}
    </div>
  );
};

export default Accordion;

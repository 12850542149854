import React from "react";
import Page from "../skeletons/Page";
import Highlight from "../elements/atoms/Highlight";
import LazyImage from "../elements/atoms/LazyImage";


function NotFound() {
  return (
    <Page>
      <div className="w-full text-center pt-32">
        <div className="py-2 px-8">
          <div className="flex flex-col md:flex-row items-center">
            <LazyImage src="/assets/404.webp" alt="Not found" className="max-h-[240px]" />
            <div className="text-left p-10 ">
              <Highlight className="font-black text-center text-3xl md:text-5xl">
                404 - Not found!
              </Highlight>
              <div className="font-medium md:text-xl">
                Looks like this page got hashed and salted... and then lost.
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
}

export default NotFound;

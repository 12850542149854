// src/components/Topbar.tsx
import React, { useEffect, useState } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
 import Highlight from "../../elements/atoms/Highlight";
import Button from "../../elements/atoms/Button";
import NavLink from "../../elements/atoms/NavLink";
import { NavLink as NL } from "react-router-dom";
import Logo from "../../elements/atoms/Logo";

const Topbar: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

 const [ links, setLinks ] = useState<any[]>([])

useEffect(() => {
  setLinks([
    { to: "/faq", label: "FAQ" },  
    { to: "/challenge", label: "Challenge" },  
    { to: "/benchmarks", label: "Benchmarks" },  
    { to: "https://app.hashrepublic.net", label: "App", style: 'outlined' },
  ]);
 }, [])


  return (
    <header className=" text-white py-5 z-50">
      <div className="container mx-auto px-4 py-2 flex justify-between items-center">
        <div className=" flex  items-center font-bold  ">
          <NL to="/">
          <Highlight className="whitespace-nowrap"><Logo color="gradient" className="text-white inline-block w-9 h-9 relative mr-4 "/><span> Hash Republic </span></Highlight>
          </NL>
        </div>
        {/* Desktop Menu */}
        <nav className="hidden md:flex items-center space-x-6">
          {links.map((link) => {
            if(link.style === 'outlined') {
                return <Button key={link.to} navlink={link.to} type="outlined">
                    {link.label}
                </Button>
            }
            return (
              <NavLink
                key={link.to}
                to={link.to}
              >
                {link.label}
              </NavLink>
            );
          })}
        </nav>
        {/* Mobile Menu Button */}
        <div className="md:hidden p-2 flex items-center">
          <button
            onClick={toggleMenu}
            aria-label="Toggle Menu"
            className="opacity-50 hover:opacity-70 active:opacity-100 focus:outline-none"
          >
            {menuOpen ? (
              <AiOutlineClose size={24} />
            ) : (
              <AiOutlineMenu size={24} />
            )}
          </button>
        </div>
      </div>
      {/* Mobile Menu */}
      {menuOpen && (
        <nav className="md:hidden  shadow-md">
          <ul className="space-y-2 py-2 px-4">
            {links.map((link) => {
              return (
                <li key={link.to}>
                  <NavLink
                    to={link.to}
                  >
                    {link.label}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </nav>
      )}
    </header>
  );
};

export default Topbar;

export const RTX4090 = {
    "0": {
        "name": "MD5",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "66891.5 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "156.4 GH/s" },
      "ratio": 2.33,
    },
  
    "10": {
        "name": "md5($pass.$salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "56145.5 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "156.3 GH/s" },
      "ratio": 2.78,
    },
  
    "11": {
        "name": "Joomla < 2.5.18",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "55987.2 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "153.7 GH/s" },
      "ratio": 2.74,
    },
  
    "12": {
        "name": "PostgreSQL",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "56573.6 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "152.7 GH/s" },
      "ratio": 2.69,
    },
  
    "20": {
        "name": "md5($salt.$pass)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "59816.8 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "83709.1 MH/s" },
      "ratio": 1.39,
    },
  
    "21": {
        "name": "osCommerce, xt:Commerce",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "58968.3 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "85222.9 MH/s" },
      "ratio": 1.44,
    },
  
    "22": {
        "name": "Juniper NetScreen/SSG (ScreenOS)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "60128.4 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "83921.4 MH/s" },
      "ratio": 1.39,
    },
  
    "23": {
        "name": "Skype",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "59407.8 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "83680.8 MH/s" },
      "ratio": 1.4,
    },
  
    "24": {
        "name": "SolarWinds Serv-U",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "58906.2 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "81174.8 MH/s" },
      "ratio": 1.37,
    },
  
    "30": {
        "name": "md5(utf16le($pass).$salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "28527.3 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 27, "speed": "151.1 GH/s" },
      "ratio": 5.29,
    },
  
    "40": {
        "name": "md5($salt.utf16le($pass))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "29848.8 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 27, "speed": "84740.1 MH/s" },
      "ratio": 2.83,
    },
  
    "50": {
        "name": "HMAC-MD5 (key = $pass)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "19760.0 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "25386.7 MH/s" },
      "ratio": 1.28,
    },
  
    "60": {
        "name": "HMAC-MD5 (key = $salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "40264.7 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "52523.5 MH/s" },
      "ratio": 1.3,
    },
  
    "70": {
        "name": "md5(utf16le($pass))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "30046.7 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 27, "speed": "148.9 GH/s" },
      "ratio": 4.95,
    },
  
    "100": {
        "name": "SHA1",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "33654.6 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "48928.7 MH/s" },
      "ratio": 1.45,
    },
  
    "101": {
        "name": "nsldap, SHA-1(Base64), Netscape LDAP SHA",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "33896.0 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "48963.4 MH/s" },
      "ratio": 1.44,
    },
  
    "110": {
        "name": "sha1($pass.$salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "31553.3 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "49208.3 MH/s" },
      "ratio": 1.55,
    },
  
    "111": {
        "name": "nsldaps, SSHA-1(Base64), Netscape LDAP SSHA",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "31381.3 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "48831.4 MH/s" },
      "ratio": 1.55,
    },
  
    "112": {
        "name": "Oracle S: Type (Oracle 11+)",
      "regular": { "minLen": 0, "maxLen": 30, "speed": "31409.6 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 30, "speed": "49218.6 MH/s" },
      "ratio": 1.56,
    },
  
    "120": {
        "name": "sha1($salt.$pass)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "32433.6 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "37780.7 MH/s" },
      "ratio": 1.16,
    },
  
    "121": {
        "name": "SMF (Simple Machines Forum) > v1.1",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "32453.4 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "37926.6 MH/s" },
      "ratio": 1.16,
    },
  
    "122": {
        "name": "macOS v10.4, macOS v10.5, macOS v10.6",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "32446.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "37869.6 MH/s" },
      "ratio": 1.16,
    },
  
    "124": {
        "name": "Django (SHA-1)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "32499.6 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "37761.0 MH/s" },
      "ratio": 1.16,
    },
  
    "125": {
        "name": "ArubaOS",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "32377.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "37769.4 MH/s" },
      "ratio": 1.16,
    },
  
    "130": {
        "name": "sha1(utf16le($pass).$salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "30729.5 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 27, "speed": "49342.7 MH/s" },
      "ratio": 1.6,
    },
  
    "131": {
        "name": "MSSQL (2000)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "30784.4 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 27, "speed": "49358.5 MH/s" },
      "ratio": 1.6,
    },
  
    "132": {
        "name": "MSSQL (2005)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "30740.5 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 27, "speed": "49464.1 MH/s" },
      "ratio": 1.6,
    },
  
    "133": {
        "name": "PeopleSoft",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "32860.0 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 27, "speed": "49087.5 MH/s" },
      "ratio": 1.49,
    },
  
    "140": {
        "name": "sha1($salt.utf16le($pass))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "31930.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 27, "speed": "37905.7 MH/s" },
      "ratio": 1.18,
    },
  
    "141": {
        "name": "Episerver 6.x < .NET 4",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "31982.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 27, "speed": "37864.9 MH/s" },
      "ratio": 1.18,
    },
  
    "150": {
        "name": "HMAC-SHA1 (key = $pass)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "9198.2 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "11333.0 MH/s" },
      "ratio": 1.23,
    },
  
    "160": {
        "name": "HMAC-SHA1 (key = $salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "18544.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "20909.7 MH/s" },
      "ratio": 1.12,
    },
  
    "170": {
        "name": "sha1(utf16le($pass))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "33697.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 27, "speed": "49117.2 MH/s" },
      "ratio": 1.45,
    },
  
    "200": {
        "name": "MySQL323",
      "regular": { "minLen": 0, "maxLen": 55, "speed": "485.4 GH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "486.2 GH/s" },
      "ratio": 1,
    },
  
    "300": {
        "name": "MySQL4.1/MySQL5",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "18550.2 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "21433.6 MH/s" },
      "ratio": 1.15,
    },
  
    "400": {
        "name": "phpass",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "46488.7 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 39, "speed": "48106.3 kH/s" },
      "ratio": 1.03,
    },
  
    "500": {
        "name": "md5crypt, MD5 (Unix), Cisco-IOS $1$ (MD5)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "3642.8 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 15, "speed": "67747.7 kH/s" },
      "ratio": 18.59,
    },
  
    "501": {
        "name": "Juniper IVE",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "3641.3 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 15, "speed": "67750.6 kH/s" },
      "ratio": 18.6,
    },
  
    "600": {
        "name": "BLAKE2b-512",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "3170.2 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "12415.8 MH/s" },
      "ratio": 3.91,
    },
  
    "610": {
        "name": "BLAKE2b-512($pass.$salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "2108.6 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "12381.8 MH/s" },
      "ratio": 5.87,
    },
  
    "620": {
        "name": "BLAKE2b-512($salt.$pass)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "5101.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "11556.9 MH/s" },
      "ratio": 2.26,
    },
  
    "900": {
        "name": "MD4",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "89427.3 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "279.8 GH/s" },
      "ratio": 3.12,
    },
  
    "1000": {
        "name": "NTLM",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "30759.3 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 27, "speed": "272.8 GH/s" },
      "ratio": 8.86,
    },
  
    "1100": {
        "name": "Domain Cached Credentials (DCC), MS Cache",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "27488.4 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 27, "speed": "71268.6 MH/s" },
      "ratio": 2.59,
    },
  
    "1300": {
        "name": "SHA2-224",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "15561.4 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "20673.9 MH/s" },
      "ratio": 1.32,
    },
  
    "1400": {
        "name": "SHA2-256",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "15592.6 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "20963.7 MH/s" },
      "ratio": 1.34,
    },
  
    "1410": {
        "name": "sha256($pass.$salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "15230.3 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "20976.3 MH/s" },
      "ratio": 1.37,
    },
  
    "1411": {
        "name": "SSHA-256(Base64), LDAP {SSHA256}",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "15308.5 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "21180.7 MH/s" },
      "ratio": 1.38,
    },
  
    "1420": {
        "name": "sha256($salt.$pass)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "15384.6 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "18853.8 MH/s" },
      "ratio": 1.22,
    },
  
    "1421": {
        "name": "hMailServer",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "15461.8 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "18791.8 MH/s" },
      "ratio": 1.21,
    },
  
    "1430": {
        "name": "sha256(utf16le($pass).$salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "15108.9 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 27, "speed": "20930.5 MH/s" },
      "ratio": 1.38,
    },
  
    "1440": {
        "name": "sha256($salt.utf16le($pass))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "15263.8 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 27, "speed": "18463.2 MH/s" },
      "ratio": 1.2,
    },
  
    "1441": {
        "name": "Episerver 6.x >= .NET 4",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "15358.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 27, "speed": "18666.5 MH/s" },
      "ratio": 1.21,
    },
  
    "1450": {
        "name": "HMAC-SHA256 (key = $pass)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "4009.5 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "4090.2 MH/s" },
      "ratio": 1.02,
    },
  
    "1460": {
        "name": "HMAC-SHA256 (key = $salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "4711.4 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "9107.1 MH/s" },
      "ratio": 1.93,
    },
  
    "1470": {
        "name": "sha256(utf16le($pass))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "15716.6 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 27, "speed": "21216.9 MH/s" },
      "ratio": 1.34,
    },
  
    "1500": {
        "name": "descrypt, DES (Unix), Traditional DES",
      "regular": { "minLen": 0, "maxLen": 8, "speed": "6337.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 8, "speed": "6349.2 MH/s" },
      "ratio": 1,
    },
  
    "1600": {
        "name": "Apache $apr1$ MD5, md5apr1, MD5 (APR)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "3666.4 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 15, "speed": "67805.5 kH/s" },
      "ratio": 18.49,
    },
  
    "1700": {
        "name": "SHA2-512",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "6156.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "6176.0 MH/s" },
      "ratio": 1,
    },
  
    "1710": {
        "name": "sha512($pass.$salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "1906.3 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "6106.7 MH/s" },
      "ratio": 3.2,
    },
  
    "1711": {
        "name": "SSHA-512(Base64), LDAP {SSHA512}",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "1901.7 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "6184.6 MH/s" },
      "ratio": 3.25,
    },
  
    "1720": {
        "name": "sha512($salt.$pass)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "5969.6 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "6156.9 MH/s" },
      "ratio": 1.03,
    },
  
    "1722": {
        "name": "macOS v10.7",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "5971.8 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "6106.8 MH/s" },
      "ratio": 1.02,
    },
  
    "1730": {
        "name": "sha512(utf16le($pass).$salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "1917.0 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 27, "speed": "6161.4 MH/s" },
      "ratio": 3.21,
    },
  
    "1731": {
        "name": "MSSQL (2012, 2014)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "1914.7 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 27, "speed": "6112.3 MH/s" },
      "ratio": 3.19,
    },
  
    "1740": {
        "name": "sha512($salt.utf16le($pass))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "5900.6 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 27, "speed": "6171.3 MH/s" },
      "ratio": 1.04,
    },
  
    "1750": {
        "name": "HMAC-SHA512 (key = $pass)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "1235.6 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "1517.0 MH/s" },
      "ratio": 1.22,
    },
  
    "1760": {
        "name": "HMAC-SHA512 (key = $salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "2970.2 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "3047.0 MH/s" },
      "ratio": 1.02,
    },
  
    "1770": {
        "name": "sha512(utf16le($pass))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "6073.3 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 27, "speed": "6177.0 MH/s" },
      "ratio": 1.01,
    },
  
    "1800": {
        "name": "sha512crypt $6$, SHA512 (Unix)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "338.5 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 15, "speed": "1090.8 kH/s" },
      "ratio": 3.22,
    },
  
    "2000": {
        "name": "STDOUT",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "16933.9 GH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "16566.6 GH/s" },
      "ratio": 0.97,
    },
  
    "2100": {
        "name": "Domain Cached Credentials 2 (DCC2), MS Cache 2",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "2008.7 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "2036.7 kH/s" },
      "ratio": 1.01,
    },
  
    "2400": {
        "name": "Cisco-PIX MD5",
      "regular": { "minLen": 0, "maxLen": 31, "speed": "112.3 GH/s" },
      "optimized": { "minLen": 0, "maxLen": 31, "speed": "112.5 GH/s" },
      "ratio": 1,
    },
  
    "2410": {
        "name": "Cisco-ASA MD5",
      "regular": { "minLen": 0, "maxLen": 47, "speed": "113.2 GH/s" },
      "optimized": { "minLen": 0, "maxLen": 47, "speed": "113.3 GH/s" },
      "ratio": 1,
    },
  
    "2600": {
        "name": "md5(md5($pass))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "32334.7 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "46685.1 MH/s" },
      "ratio": 1.44,
    },
  
    "2611": {
        "name": "vBulletin < v3.8.5",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "31357.7 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "47047.7 MH/s" },
      "ratio": 1.5,
    },
  
    "2612": {
        "name": "PHPS",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "31435.4 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "46623.8 MH/s" },
      "ratio": 1.48,
    },
  
    "2630": {
        "name": "md5(md5($pass.$salt))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "33260.6 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "46478.5 MH/s" },
      "ratio": 1.39,
    },
  
    "2711": {
        "name": "vBulletin >= v3.8.5",
      "regular": { "minLen": 0, "maxLen": 55, "speed": "32590.3 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "32558.8 MH/s" },
      "ratio": 0.99,
    },
  
    "2811": {
        "name": "MyBB 1.2+, IPB2+ (Invision Power Board)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "28205.2 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "33848.4 MH/s" },
      "ratio": 1.2,
    },
  
    "3000": {
        "name": "LM",
      "regular": { "minLen": 0, "maxLen": 7, "speed": "149.3 GH/s" },
      "optimized": { "minLen": 0, "maxLen": 7, "speed": "149.1 GH/s" },
      "ratio": 0.99,
    },
  
    "3100": {
        "name": "Oracle H: Type (Oracle 7+)",
      "regular": { "minLen": 0, "maxLen": 30, "speed": "3803.8 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 30, "speed": "3775.6 MH/s" },
      "ratio": 0.99,
    },
  
    "3200": {
        "name": "bcrypt $2*$, Blowfish (Unix)",
      "regular": { "minLen": 0, "maxLen": 72, "speed": "7070 H/s" },
      "optimized": { "minLen": 0, "maxLen": 72, "speed": "7079 H/s" },
      "ratio": 1,
    },
  
    "3500": {
        "name": "md5(md5(md5($pass)))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "22812.3 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "29562.6 MH/s" },
      "ratio": 1.29,
    },
  
    "3610": {
        "name": "md5(md5(md5($pass)).$salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "22394.0 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "29640.4 MH/s" },
      "ratio": 1.32,
    },
  
    "3710": {
        "name": "md5($salt.md5($pass))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "30144.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "41710.0 MH/s" },
      "ratio": 1.38,
    },
  
    "3711": {
        "name": "MediaWiki B type",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "30088.7 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "41931.1 MH/s" },
      "ratio": 1.39,
    },
  
    "3730": {
        "name": "md5($salt1.strtoupper(md5($salt2.$pass)))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "28152.7 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "28293.7 MH/s" },
      "ratio": 1,
    },
  
    "3800": {
        "name": "md5($salt.$pass.$salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "50893.6 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "85143.6 MH/s" },
      "ratio": 1.67,
    },
  
    "3910": {
        "name": "md5(md5($pass).md5($salt))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "27480.0 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "32346.2 MH/s" },
      "ratio": 1.17,
    },
  
    "4010": {
        "name": "md5($salt.md5($salt.$pass))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "29302.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "36580.0 MH/s" },
      "ratio": 1.24,
    },
  
    "4110": {
        "name": "md5($salt.md5($pass.$salt))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "29654.6 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "42251.8 MH/s" },
      "ratio": 1.42,
    },
  
    "4300": {
        "name": "md5(strtoupper(md5($pass)))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "31998.0 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "46563.7 MH/s" },
      "ratio": 1.45,
    },
  
    "4400": {
        "name": "md5(sha1($pass))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "23170.0 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "26516.8 MH/s" },
      "ratio": 1.14,
    },
  
    "4410": {
        "name": "md5(sha1($pass).$salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "16711.6 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "19547.0 MH/s" },
      "ratio": 1.16,
    },
  
    "4420": {
        "name": "md5(sha1($pass.$salt))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "21870.6 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "26677.6 MH/s" },
      "ratio": 1.21,
    },
  
    "4430": {
        "name": "md5(sha1($salt.$pass))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "22329.4 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "24549.6 MH/s" },
      "ratio": 1.09,
    },
  
    "4500": {
        "name": "sha1(sha1($pass))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "17570.2 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "18839.4 MH/s" },
      "ratio": 1.07,
    },
  
    "4510": {
        "name": "sha1(sha1($pass).$salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "16010.7 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "18195.1 MH/s" },
      "ratio": 1.13,
    },
  
    "4520": {
        "name": "sha1($salt.sha1($pass))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "11358.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "11868.4 MH/s" },
      "ratio": 1.04,
    },
  
    "4521": {
        "name": "Redmine",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "11356.2 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "11904.1 MH/s" },
      "ratio": 1.04,
    },
  
    "4522": {
        "name": "PunBB",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "16322.3 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "17800.5 MH/s" },
      "ratio": 1.09,
    },
  
    "4700": {
        "name": "sha1(md5($pass))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "24186.0 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "26806.0 MH/s" },
      "ratio": 1.1,
    },
  
    "4710": {
        "name": "sha1(md5($pass).$salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "21933.0 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "25713.4 MH/s" },
      "ratio": 1.17,
    },
  
    "4711": {
        "name": "Huawei sha1(md5($pass).$salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "21939.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "25823.6 MH/s" },
      "ratio": 1.17,
    },
  
    "4800": {
        "name": "iSCSI CHAP authentication, MD5(CHAP)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "50634.8 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "111.4 GH/s" },
      "ratio": 2.2,
    },
  
    "4900": {
        "name": "sha1($salt.$pass.$salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "5561.3 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "35153.4 MH/s" },
      "ratio": 6.32,
    },
  
    "5000": {
        "name": "sha1(sha1($salt.$pass.$salt))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "5586.5 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "17306.3 MH/s" },
      "ratio": 3.09,
    },
  
    "5100": {
        "name": "Half MD5",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "65244.9 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "99623.0 MH/s" },
      "ratio": 1.52,
    },
  
    "5200": {
        "name": "Password Safe v3",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "8490.3 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "8418.3 kH/s" },
      "ratio": 0.99,
    },
  
    "5300": {
        "name": "IKE-PSK MD5",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "5474.4 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "5920.4 MH/s" },
      "ratio": 1.08,
    },
  
    "5400": {
        "name": "IKE-PSK SHA1",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "2314.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "2541.1 MH/s" },
      "ratio": 1.09,
    },
  
    "5500": {
        "name": "NetNTLMv1 / NetNTLMv1+ESS",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "31312.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 27, "speed": "147.1 GH/s" },
      "ratio": 4.69,
    },
  
    "5600": {
        "name": "NetNTLMv2",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "8937.8 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 27, "speed": "11162.8 MH/s" },
      "ratio": 1.24,
    },
  
    "5700": {
        "name": "Cisco-IOS type 4 (SHA256)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "15426.6 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "21198.9 MH/s" },
      "ratio": 1.37,
    },
  
    "5800": {
        "name": "Samsung Android Password/PIN",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "27725.4 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 16, "speed": "33620.3 kH/s" },
      "ratio": 1.21,
    },
  
    "6000": {
        "name": "RIPEMD-160",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "25639.8 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "33295.6 MH/s" },
      "ratio": 1.29,
    },
  
    "6050": {
        "name": "HMAC-RIPEMD160 (key = $pass)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "6940.7 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "6925.4 MH/s" },
      "ratio": 0.99,
    },
  
    "6060": {
        "name": "HMAC-RIPEMD160 (key = $salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "13893.8 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "13893.7 MH/s" },
      "ratio": 0.99,
    },
  
    "6100": {
        "name": "Whirlpool",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "2506.6 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "3283.3 MH/s" },
      "ratio": 1.3,
    },
  
    "6211": {
        "name": "TrueCrypt RIPEMD160 + XTS 512 bit (legacy)",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "1849.0 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "1830.2 kH/s" },
      "ratio": 0.98,
    },
  
    "6212": {
        "name": "TrueCrypt RIPEMD160 + XTS 1024 bit (legacy)",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "998.6 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "988.3 kH/s" },
      "ratio": 0.98,
    },
  
    "6213": {
        "name": "TrueCrypt RIPEMD160 + XTS 1536 bit (legacy)",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "675.8 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "676.2 kH/s" },
      "ratio": 1,
    },
  
    "6221": {
        "name": "TrueCrypt SHA512 + XTS 512 bit (legacy)",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "2771.1 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "2773.7 kH/s" },
      "ratio": 1,
    },
  
    "6222": {
        "name": "TrueCrypt SHA512 + XTS 1024 bit (legacy)",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "1304.7 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "1288.4 kH/s" },
      "ratio": 0.98,
    },
  
    "6223": {
        "name": "TrueCrypt SHA512 + XTS 1536 bit (legacy)",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "851.5 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "837.0 kH/s" },
      "ratio": 0.98,
    },
  
    "6231": {
        "name": "TrueCrypt Whirlpool + XTS 512 bit (legacy)",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "375.0 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "374.7 kH/s" },
      "ratio": 0.99,
    },
  
    "6232": {
        "name": "TrueCrypt Whirlpool + XTS 1024 bit (legacy)",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "185.8 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "185.6 kH/s" },
      "ratio": 0.99,
    },
  
    "6233": {
        "name": "TrueCrypt Whirlpool + XTS 1536 bit (legacy)",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "121.6 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "121.4 kH/s" },
      "ratio": 0.99,
    },
  
    "6241": {
        "name": "TrueCrypt RIPEMD160 + XTS 512 bit + boot-mode (legacy)",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "3420.8 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "3365.3 kH/s" },
      "ratio": 0.98,
    },
  
    "6242": {
        "name": "TrueCrypt RIPEMD160 + XTS 1024 bit + boot-mode (legacy)",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "1769.1 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "1767.1 kH/s" },
      "ratio": 0.99,
    },
  
    "6243": {
        "name": "TrueCrypt RIPEMD160 + XTS 1536 bit + boot-mode (legacy)",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "1175.4 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "1194.8 kH/s" },
      "ratio": 1.01,
    },
  
    "6300": {
        "name": "AIX {smd5}",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "3631.0 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 15, "speed": "67986.2 kH/s" },
      "ratio": 18.72,
    },
  
    "6400": {
        "name": "AIX {ssha256}",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "104.5 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "104.7 MH/s" },
      "ratio": 1,
    },
  
    "6500": {
        "name": "AIX {ssha512}",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "43537.3 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "43622.9 kH/s" },
      "ratio": 1,
    },
  
    "6600": {
        "name": "1Password, agilekeychain",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "19995.7 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "19724.6 kH/s" },
      "ratio": 0.98,
    },
  
    "6700": {
        "name": "AIX {ssha1}",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "238.7 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "237.4 MH/s" },
      "ratio": 0.99,
    },
  
    "6800": {
        "name": "LastPass + LastPass sniffed",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "86915 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "86918 H/s" },
      "ratio": 1,
    },
  
    "6900": {
        "name": "GOST R 34.11-94",
      "regular": { "minLen": 0, "maxLen": 32, "speed": "2347.0 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 32, "speed": "2350.2 MH/s" },
      "ratio": 1,
    },
  
    "7000": {
        "name": "FortiGate (FortiOS)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "30160.2 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 19, "speed": "42767.1 MH/s" },
      "ratio": 1.41,
    },
  
    "7100": {
        "name": "macOS v10.8+ (PBKDF2-SHA512)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "3040.6 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "3039.2 kH/s" },
      "ratio": 0.99,
    },
  
    "7200": {
        "name": "GRUB 2",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "3037.8 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "3047.8 kH/s" },
      "ratio": 1,
    },
  
    "7300": {
        "name": "IPMI2 RAKP HMAC-SHA1",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "6143.4 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "6568.3 MH/s" },
      "ratio": 1.06,
    },
  
    "7350": {
        "name": "IPMI2 RAKP HMAC-MD5",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "15959.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "20049.4 MH/s" },
      "ratio": 1.25,
    },
  
    "7400": {
        "name": "sha256crypt $5$, SHA256 (Unix)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "652.1 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 15, "speed": "1955.3 kH/s" },
      "ratio": 2.99,
    },
  
    "7401": {
        "name": "MySQL $A$ (sha256crypt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "657.2 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 15, "speed": "1864.9 kH/s" },
      "ratio": 2.83,
    },
  
    "7500": {
        "name": "Kerberos 5, etype 23, AS-REQ Pre-Auth",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "2601.0 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "3252.7 MH/s" },
      "ratio": 1.25,
    },
  
    "7700": {
        "name": "SAP CODVN B (BCODE)",
      "regular": { "minLen": 0, "maxLen": 8, "speed": "7046.3 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 8, "speed": "7044.1 MH/s" },
      "ratio": 0.99,
    },
  
    "7701": {
        "name": "SAP CODVN B (BCODE) from RFC_READ_TABLE",
      "regular": { "minLen": 0, "maxLen": 55, "speed": "7044.7 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "7098.9 MH/s" },
      "ratio": 1,
    },
  
    "7800": {
        "name": "SAP CODVN F/G (PASSCODE)",
      "regular": { "minLen": 0, "maxLen": 55, "speed": "5504.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "5527.6 MH/s" },
      "ratio": 1,
    },
  
    "7801": {
        "name": "SAP CODVN F/G (PASSCODE) from RFC_READ_TABLE",
      "regular": { "minLen": 0, "maxLen": 55, "speed": "5487.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "5519.5 MH/s" },
      "ratio": 1,
    },
  
    "7900": {
        "name": "Drupal7",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "384.6 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "384.7 kH/s" },
      "ratio": 1,
    },
  
    "8000": {
        "name": "Sybase ASE",
      "regular": { "minLen": 0, "maxLen": 30, "speed": "2644.0 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 30, "speed": "2701.6 MH/s" },
      "ratio": 1.02,
    },
  
    "8100": {
        "name": "Citrix NetScaler (SHA1)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "7325.2 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "40938.6 MH/s" },
      "ratio": 5.58,
    },
  
    "8200": {
        "name": "1Password, cloudkeychain",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "79161 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "79171 H/s" },
      "ratio": 1,
    },
  
    "8300": {
        "name": "DNSSEC (NSEC3)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "2944.4 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "18183.6 MH/s" },
      "ratio": 6.17,
    },
  
    "8400": {
        "name": "WBB3 (Woltlab Burning Board)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "6520.8 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "7966.9 MH/s" },
      "ratio": 1.22,
    },
  
    "8500": {
        "name": "RACF",
      "regular": { "minLen": 0, "maxLen": 8, "speed": "20156.3 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 8, "speed": "19721.4 MH/s" },
      "ratio": 0.97,
    },
  
    "8600": {
        "name": "Lotus Notes/Domino 5",
      "regular": { "minLen": 0, "maxLen": 16, "speed": "2027.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 16, "speed": "2031.4 MH/s" },
      "ratio": 1,
    },
  
    "8700": {
        "name": "Lotus Notes/Domino 6",
      "regular": { "minLen": 0, "maxLen": 32, "speed": "668.0 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 32, "speed": "667.9 MH/s" },
      "ratio": 0.99,
    },
  
    "8800": {
        "name": "Android FDE <= 4.3",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "5070.2 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "4998.4 kH/s" },
      "ratio": 0.98,
    },
  
    "8900": {
        "name": "scrypt",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "9573 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "9570 H/s" },
      "ratio": 0.99,
    },
  
    "9000": {
        "name": "Password Safe v2",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "4314.7 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "4315.4 kH/s" },
      "ratio": 1,
    },
  
    "9100": {
        "name": "Lotus Notes/Domino 8",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "4060.2 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "4061.6 kH/s" },
      "ratio": 1,
    },
  
    "9200": {
        "name": "Cisco-IOS $8$ (PBKDF2-SHA256)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "404.7 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "407.1 kH/s" },
      "ratio": 1,
    },
  
    "9300": {
        "name": "Cisco-IOS $9$ (scrypt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "88787 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "88737 H/s" },
      "ratio": 0.99,
    },
  
    "9400": {
        "name": "MS Office 2007",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "831.4 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "824.9 kH/s" },
      "ratio": 0.99,
    },
  
    "9500": {
        "name": "MS Office 2010",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "413.2 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "416.1 kH/s" },
      "ratio": 1,
    },
  
    "9600": {
        "name": "MS Office 2013",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "66307 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "66463 H/s" },
      "ratio": 1,
    },
  
    "9700": {
        "name": "MS Office <= 2003 $0/$1, MD5 + RC4",
      "regular": { "minLen": 0, "maxLen": 15, "speed": "2602.4 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 15, "speed": "2605.2 MH/s" },
      "ratio": 1,
    },
  
    "9710": {
        "name": "MS Office <= 2003 $0/$1, MD5 + RC4, collider #1",
      "regular": { "minLen": 5, "maxLen": 5, "speed": "3614.7 MH/s" },
      "optimized": { "minLen": 5, "maxLen": 5, "speed": "3615.1 MH/s" },
      "ratio": 1,
    },
  
    "9720": {
        "name": "MS Office <= 2003 $0/$1, MD5 + RC4, collider #2",
      "regular": { "minLen": 0, "maxLen": 15, "speed": "13054.7 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 15, "speed": "13053.4 MH/s" },
      "ratio": 0.99,
    },
  
    "9800": {
        "name": "MS Office <= 2003 $3/$4, SHA1 + RC4",
      "regular": { "minLen": 0, "maxLen": 15, "speed": "2757.7 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 15, "speed": "2759.6 MH/s" },
      "ratio": 1,
    },
  
    "9810": {
        "name": "MS Office <= 2003 $3, SHA1 + RC4, collider #1",
      "regular": { "minLen": 5, "maxLen": 5, "speed": "3488.4 MH/s" },
      "optimized": { "minLen": 5, "maxLen": 5, "speed": "3492.0 MH/s" },
      "ratio": 1,
    },
  
    "9820": {
        "name": "MS Office <= 2003 $3, SHA1 + RC4, collider #2",
      "regular": { "minLen": 0, "maxLen": 15, "speed": "20036.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 15, "speed": "17988.0 MH/s" },
      "ratio": 0.89,
    },
  
    "9900": {
        "name": "Radmin2",
      "regular": { "minLen": 0, "maxLen": 100, "speed": "40226.9 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "55575.8 MH/s" },
      "ratio": 1.38,
    },
  
    "10000": {
        "name": "Django (PBKDF2-SHA256)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "808.6 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "814.3 kH/s" },
      "ratio": 1,
    },
  
    "10100": {
        "name": "SipHash",
      "regular": { "minLen": 0, "maxLen": 55, "speed": "169.6 GH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "169.7 GH/s" },
      "ratio": 1,
    },
  
    "10200": {
        "name": "CRAM-MD5",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "19813.6 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "25425.6 MH/s" },
      "ratio": 1.28,
    },
  
    "10300": {
        "name": "SAP CODVN H (PWDSALTEDHASH) iSSHA-1",
      "regular": { "minLen": 0, "maxLen": 40, "speed": "32657.8 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 40, "speed": "32529.0 kH/s" },
      "ratio": 0.99,
    },
  
    "10400": {
        "name": "PDF 1.1 - 1.3 (Acrobat 2 - 4)",
      "regular": { "minLen": 0, "maxLen": 32, "speed": "3922.7 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 32, "speed": "3928.0 MH/s" },
      "ratio": 1,
    },
  
    "10410": {
        "name": "PDF 1.1 - 1.3 (Acrobat 2 - 4), collider #1",
      "regular": { "minLen": 5, "maxLen": 5, "speed": "4013.8 MH/s" },
      "optimized": { "minLen": 5, "maxLen": 5, "speed": "4013.5 MH/s" },
      "ratio": 0.99,
    },
  
    "10420": {
        "name": "PDF 1.1 - 1.3 (Acrobat 2 - 4), collider #2",
      "regular": { "minLen": 0, "maxLen": 32, "speed": "53857.9 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 32, "speed": "53834.3 MH/s" },
      "ratio": 0.99,
    },
  
    "10500": {
        "name": "PDF 1.4 - 1.6 (Acrobat 5 - 8)",
      "regular": { "minLen": 0, "maxLen": 32, "speed": "161.3 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 32, "speed": "161.3 MH/s" },
      "ratio": 1,
    },
  
    "10600": {
        "name": "PDF 1.7 Level 3 (Acrobat 9)",
      "regular": { "minLen": 0, "maxLen": 127, "speed": "15317.2 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "21034.6 MH/s" },
      "ratio": 1.37,
    },
  
    "10700": {
        "name": "PDF 1.7 Level 8 (Acrobat 10 - 11)",
      "regular": { "minLen": 0, "maxLen": 127, "speed": "55715 H/s" },
      "optimized": { "minLen": 0, "maxLen": 16, "speed": "431.3 kH/s" },
      "ratio": 7.74,
    },
  
    "10800": {
        "name": "SHA2-384",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "6140.9 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "6179.2 MH/s" },
      "ratio": 1,
    },
  
    "10810": {
        "name": "sha384($pass.$salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "1902.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "6213.2 MH/s" },
      "ratio": 3.26,
    },
  
    "10820": {
        "name": "sha384($salt.$pass)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "5952.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "6197.4 MH/s" },
      "ratio": 1.04,
    },
  
    "10830": {
        "name": "sha384(utf16le($pass).$salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "1917.6 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 27, "speed": "6114.2 MH/s" },
      "ratio": 3.18,
    },
  
    "10840": {
        "name": "sha384($salt.utf16le($pass))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "5917.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 27, "speed": "6176.5 MH/s" },
      "ratio": 1.04,
    },
  
    "10870": {
        "name": "sha384(utf16le($pass))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "6048.7 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 27, "speed": "6220.4 MH/s" },
      "ratio": 1.02,
    },
  
    "10900": {
        "name": "PBKDF2-HMAC-SHA256",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "8490.4 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "8492.6 kH/s" },
      "ratio": 1,
    },
  
    "10901": {
        "name": "RedHat 389-DS LDAP (PBKDF2-HMAC-SHA256)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "986.7 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "993.6 kH/s" },
      "ratio": 1,
    },
  
    "11000": {
        "name": "PrestaShop",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "35069.7 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "58503.9 MH/s" },
      "ratio": 1.66,
    },
  
    "11100": {
        "name": "PostgreSQL CRAM (MD5)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "33669.7 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "47044.9 MH/s" },
      "ratio": 1.39,
    },
  
    "11200": {
        "name": "MySQL CRAM (SHA1)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "8955.8 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "12856.3 MH/s" },
      "ratio": 1.43,
    },
  
    "11300": {
        "name": "Bitcoin/Litecoin wallet.dat",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "32603 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "32599 H/s" },
      "ratio": 0.99,
    },
  
    "11400": {
        "name": "SIP digest authentication (MD5)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "21050.6 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "21040.9 MH/s" },
      "ratio": 0.99,
    },
  
    "11500": {
        "name": "CRC32",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "40881.9 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "38562.9 MH/s" },
      "ratio": 0.94,
    },
  
    "11600": {
        "name": "7-Zip",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "836.9 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 20, "speed": "2255.2 kH/s" },
      "ratio": 2.69,
    },
  
    "11700": {
        "name": "GOST R 34.11-2012 (Streebog) 256-bit, big-endian",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "471.4 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "471.5 MH/s" },
      "ratio": 1,
    },
  
    "11750": {
        "name": "HMAC-Streebog-256 (key = $pass), big-endian",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "168.5 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "166.2 MH/s" },
      "ratio": 0.98,
    },
  
    "11760": {
        "name": "HMAC-Streebog-256 (key = $salt), big-endian",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "233.0 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "231.4 MH/s" },
      "ratio": 0.99,
    },
  
    "11800": {
        "name": "GOST R 34.11-2012 (Streebog) 512-bit, big-endian",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "471.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "473.3 MH/s" },
      "ratio": 1,
    },
  
    "11850": {
        "name": "HMAC-Streebog-512 (key = $pass), big-endian",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "147.8 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "147.8 MH/s" },
      "ratio": 1,
    },
  
    "11860": {
        "name": "HMAC-Streebog-512 (key = $salt), big-endian",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "192.6 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "192.6 MH/s" },
      "ratio": 1,
    },
  
    "11900": {
        "name": "PBKDF2-HMAC-MD5",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "48170.9 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "48211.2 kH/s" },
      "ratio": 1,
    },
  
    "12000": {
        "name": "PBKDF2-HMAC-SHA1",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "19840.9 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "19882.1 kH/s" },
      "ratio": 1,
    },
  
    "12001": {
        "name": "Atlassian (PBKDF2-HMAC-SHA1)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "2042.7 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "2040.3 kH/s" },
      "ratio": 0.99,
    },
  
    "12100": {
        "name": "PBKDF2-HMAC-SHA512",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "3115.8 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "3116.1 kH/s" },
      "ratio": 1,
    },
  
    "12200": {
        "name": "eCryptfs",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "99640 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "99733 H/s" },
      "ratio": 1,
    },
  
    "12300": {
        "name": "Oracle T: Type (Oracle 12+)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "769.1 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "767.2 kH/s" },
      "ratio": 0.99,
    },
  
    "12400": {
        "name": "BSDi Crypt, Extended DES",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "18450.9 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "18516.3 kH/s" },
      "ratio": 1,
    },
  
    "12500": {
        "name": "RAR3-hp",
      "regular": { "minLen": 0, "maxLen": 128, "speed": "200.9 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 20, "speed": "73576 H/s" },
      "ratio": 0.36,
    },
  
    "12600": {
        "name": "ColdFusion 10+",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "10958.3 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "12141.6 MH/s" },
      "ratio": 1.1,
    },
  
    "12700": {
        "name": "Blockchain, My Wallet",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "355.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "355.8 MH/s" },
      "ratio": 1,
    },
  
    "12800": {
        "name": "MS-AzureSync PBKDF2-HMAC-SHA256",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "72846.4 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "72334.4 kH/s" },
      "ratio": 0.99,
    },
  
    "12900": {
        "name": "Android FDE (Samsung DEK)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "2098.7 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "2096.8 kH/s" },
      "ratio": 0.99,
    },
  
    "13000": {
        "name": "RAR5",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "265.7 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "265.5 kH/s" },
      "ratio": 0.99,
    },
  
    "13100": {
        "name": "Kerberos 5, etype 23, TGS-REP",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "2435.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "2743.0 MH/s" },
      "ratio": 1.12,
    },
  
    "13200": {
        "name": "AxCrypt 1",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "1049.7 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "1049.5 kH/s" },
      "ratio": 0.99,
    },
  
    "13300": {
        "name": "AxCrypt 1 in-memory SHA1",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "33721.7 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "45279.0 MH/s" },
      "ratio": 1.34,
    },
  
    "13400": {
        "name": "KeePass 1 (AES/Twofish) and KeePass 2 (AES)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "320.7 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "320.0 kH/s" },
      "ratio": 0.99,
    },
  
    "13500": {
        "name": "PeopleSoft PS_TOKEN",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "31752.9 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 27, "speed": "35481.7 MH/s" },
      "ratio": 1.11,
    },
  
    "13600": {
        "name": "WinZip",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "17815.3 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "17575.2 kH/s" },
      "ratio": 0.98,
    },
  
    "13711": {
        "name": "VeraCrypt RIPEMD160 + XTS 512 bit (legacy)",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "6125 H/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "6126 H/s" },
      "ratio": 1,
    },
  
    "13712": {
        "name": "VeraCrypt RIPEMD160 + XTS 1024 bit (legacy)",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "3491 H/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "3487 H/s" },
      "ratio": 0.99,
    },
  
    "13713": {
        "name": "VeraCrypt RIPEMD160 + XTS 1536 bit (legacy)",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "2445 H/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "2445 H/s" },
      "ratio": 1,
    },
  
    "13721": {
        "name": "VeraCrypt SHA512 + XTS 512 bit (legacy)",
      "regular": { "minLen": 0, "maxLen": 128, "speed": "6121 H/s" },
      "optimized": { "minLen": 0, "maxLen": 128, "speed": "6121 H/s" },
      "ratio": 1,
    },
  
    "13722": {
        "name": "VeraCrypt SHA512 + XTS 1024 bit (legacy)",
      "regular": { "minLen": 0, "maxLen": 128, "speed": "3057 H/s" },
      "optimized": { "minLen": 0, "maxLen": 128, "speed": "3059 H/s" },
      "ratio": 1,
    },
  
    "13723": {
        "name": "VeraCrypt SHA512 + XTS 1536 bit (legacy)",
      "regular": { "minLen": 0, "maxLen": 128, "speed": "2038 H/s" },
      "optimized": { "minLen": 0, "maxLen": 128, "speed": "2038 H/s" },
      "ratio": 1,
    },
  
    "13731": {
        "name": "VeraCrypt Whirlpool + XTS 512 bit (legacy)",
      "regular": { "minLen": 0, "maxLen": 128, "speed": "745 H/s" },
      "optimized": { "minLen": 0, "maxLen": 128, "speed": "745 H/s" },
      "ratio": 1,
    },
  
    "13732": {
        "name": "VeraCrypt Whirlpool + XTS 1024 bit (legacy)",
      "regular": { "minLen": 0, "maxLen": 128, "speed": "375 H/s" },
      "optimized": { "minLen": 0, "maxLen": 128, "speed": "375 H/s" },
      "ratio": 1,
    },
  
    "13733": {
        "name": "VeraCrypt Whirlpool + XTS 1536 bit (legacy)",
      "regular": { "minLen": 0, "maxLen": 128, "speed": "239 H/s" },
      "optimized": { "minLen": 0, "maxLen": 128, "speed": "239 H/s" },
      "ratio": 1,
    },
  
    "13741": {
        "name": "VeraCrypt RIPEMD160 + XTS 512 bit + boot-mode (legacy)",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "12250 H/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "12252 H/s" },
      "ratio": 1,
    },
  
    "13742": {
        "name": "VeraCrypt RIPEMD160 + XTS 1024 bit + boot-mode (legacy)",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "6965 H/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "6968 H/s" },
      "ratio": 1,
    },
  
    "13743": {
        "name": "VeraCrypt RIPEMD160 + XTS 1536 bit + boot-mode (legacy)",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "4883 H/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "4884 H/s" },
      "ratio": 1,
    },
  
    "13751": {
        "name": "VeraCrypt SHA256 + XTS 512 bit (legacy)",
      "regular": { "minLen": 0, "maxLen": 128, "speed": "8554 H/s" },
      "optimized": { "minLen": 0, "maxLen": 128, "speed": "8544 H/s" },
      "ratio": 0.99,
    },
  
    "13752": {
        "name": "VeraCrypt SHA256 + XTS 1024 bit (legacy)",
      "regular": { "minLen": 0, "maxLen": 128, "speed": "4277 H/s" },
      "optimized": { "minLen": 0, "maxLen": 128, "speed": "4275 H/s" },
      "ratio": 0.99,
    },
  
    "13753": {
        "name": "VeraCrypt SHA256 + XTS 1536 bit (legacy)",
      "regular": { "minLen": 0, "maxLen": 128, "speed": "2853 H/s" },
      "optimized": { "minLen": 0, "maxLen": 128, "speed": "2852 H/s" },
      "ratio": 0.99,
    },
  
    "13761": {
        "name": "VeraCrypt SHA256 + XTS 512 bit + boot-mode (legacy)",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "21360 H/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "21346 H/s" },
      "ratio": 0.99,
    },
  
    "13762": {
        "name": "VeraCrypt SHA256 + XTS 1024 bit + boot-mode (legacy)",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "10672 H/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "10658 H/s" },
      "ratio": 0.99,
    },
  
    "13763": {
        "name": "VeraCrypt SHA256 + XTS 1536 bit + boot-mode (legacy)",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "7104 H/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "7109 H/s" },
      "ratio": 1,
    },
  
    "13771": {
        "name": "VeraCrypt Streebog-512 + XTS 512 bit (legacy)",
      "regular": { "minLen": 0, "maxLen": 128, "speed": "296 H/s" },
      "optimized": { "minLen": 0, "maxLen": 128, "speed": "297 H/s" },
      "ratio": 1,
    },
  
    "13772": {
        "name": "VeraCrypt Streebog-512 + XTS 1024 bit (legacy)",
      "regular": { "minLen": 0, "maxLen": 128, "speed": "148 H/s" },
      "optimized": { "minLen": 0, "maxLen": 128, "speed": "148 H/s" },
      "ratio": 1,
    },
  
    "13773": {
        "name": "VeraCrypt Streebog-512 + XTS 1536 bit (legacy)",
      "regular": { "minLen": 0, "maxLen": 128, "speed": "99 H/s" },
      "optimized": { "minLen": 0, "maxLen": 128, "speed": "99 H/s" },
      "ratio": 1,
    },
  
    "13781": {
        "name": "VeraCrypt Streebog-512 + XTS 512 bit + boot-mode (legacy)",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "740 H/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "741 H/s" },
      "ratio": 1,
    },
  
    "13782": {
        "name": "VeraCrypt Streebog-512 + XTS 1024 bit + boot-mode (legacy)",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "370 H/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "371 H/s" },
      "ratio": 1,
    },
  
    "13783": {
        "name": "VeraCrypt Streebog-512 + XTS 1536 bit + boot-mode (legacy)",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "247 H/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "247 H/s" },
      "ratio": 1,
    },
  
    "13800": {
        "name": "Windows Phone 8+ PIN/password",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "5162.2 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 27, "speed": "5490.8 MH/s" },
      "ratio": 1.06,
    },
  
    "13900": {
        "name": "OpenCart",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "8824.0 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "12313.0 MH/s" },
      "ratio": 1.39,
    },
  
    "14000": {
        "name": "DES (PT = $salt, key = $pass)",
      "regular": { "minLen": 8, "maxLen": 8, "speed": "145.1 GH/s" },
      "optimized": { "minLen": 8, "maxLen": 8, "speed": "145.2 GH/s" },
      "ratio": 1,
    },
  
    "14100": {
        "name": "3DES (PT = $salt, key = $pass)",
      "regular": { "minLen": 24, "maxLen": 24, "speed": "19669.2 MH/s" },
      "optimized": { "minLen": 24, "maxLen": 24, "speed": "19591.2 MH/s" },
      "ratio": 0.99,
    },
  
    "14400": {
        "name": "sha1(CX)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "272.4 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 24, "speed": "2370.7 MH/s" },
      "ratio": 8.7,
    },
  
    "14500": {
        "name": "Linux Kernel Crypto API (2.4)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "6690.4 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "6767.7 MH/s" },
      "ratio": 1.01,
    },
  
    "14600": {
        "name": "LUKS v1 (legacy)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "82019 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "83006 H/s" },
      "ratio": 1.01,
    },
  
    "14700": {
        "name": "iTunes backup < 10.0",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "1017.2 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "1007.6 kH/s" },
      "ratio": 0.99,
    },
  
    "14800": {
        "name": "iTunes backup >= 10.0",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "906.6 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "905.9 kH/s" },
      "ratio": 0.99,
    },
  
    "14900": {
        "name": "Skip32 (PT = $salt, key = $pass)",
      "regular": { "minLen": 10, "maxLen": 10, "speed": "53271.9 MH/s" },
      "optimized": { "minLen": 10, "maxLen": 10, "speed": "48792.4 MH/s" },
      "ratio": 0.91,
    },
  
    "15000": {
        "name": "FileZilla Server >= 0.9.55",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "1904.4 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "6294.8 MH/s" },
      "ratio": 3.3,
    },
  
    "15100": {
        "name": "Juniper/NetBSD sha1crypt",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "1030.1 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "1032.3 kH/s" },
      "ratio": 1,
    },
  
    "15200": {
        "name": "Blockchain, My Wallet, V2",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "2041.7 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "2014.9 kH/s" },
      "ratio": 0.98,
    },
  
    "15300": {
        "name": "DPAPI masterkey file v1 (context 1 and 2)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "427.4 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "433.7 kH/s" },
      "ratio": 1.01,
    },
  
    "15310": {
        "name": "DPAPI masterkey file v1 (context 3)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "378.0 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "374.9 kH/s" },
      "ratio": 0.99,
    },
  
    "15400": {
        "name": "ChaCha20",
      "regular": { "minLen": 32, "maxLen": 32, "speed": "29543.6 MH/s" },
      "optimized": { "minLen": 32, "maxLen": 32, "speed": "29557.3 MH/s" },
      "ratio": 1,
    },
  
    "15500": {
        "name": "JKS Java Key Store Private Keys (SHA1)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "30811.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 16, "speed": "46489.5 MH/s" },
      "ratio": 1.5,
    },
  
    "15600": {
        "name": "Ethereum Wallet, PBKDF2-HMAC-SHA256",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "8268.6 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "8281.2 kH/s" },
      "ratio": 1,
    },
  
    "15700": {
        "name": "Ethereum Wallet, SCRYPT",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "60 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "60 H/s" },
      "ratio": 1,
    },
  
    "15900": {
        "name": "DPAPI masterkey file v2 (context 1 and 2)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "247.2 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "247.2 kH/s" },
      "ratio": 1,
    },
  
    "15910": {
        "name": "DPAPI masterkey file v2 (context 3)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "183.8 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "184.9 kH/s" },
      "ratio": 1,
    },
  
    "16000": {
        "name": "Tripcode",
      "regular": { "minLen": 0, "maxLen": 8, "speed": "1613.2 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 8, "speed": "1616.6 MH/s" },
      "ratio": 1,
    },
  
    "16100": {
        "name": "TACACS+",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "52430.2 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "104.5 GH/s" },
      "ratio": 1.99,
    },
  
    "16200": {
        "name": "Apple Secure Notes",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "435.2 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "431.4 kH/s" },
      "ratio": 0.99,
    },
  
    "16300": {
        "name": "Ethereum Pre-Sale Wallet, PBKDF2-HMAC-SHA256",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "4294.6 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "4247.8 kH/s" },
      "ratio": 0.98,
    },
  
    "16400": {
        "name": "CRAM-MD5 Dovecot",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "70051.6 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "156.4 GH/s" },
      "ratio": 2.23,
    },
  
    "16500": {
        "name": "JWT (JSON Web Token)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "4033.8 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "4024.0 MH/s" },
      "ratio": 0.99,
    },
  
    "16600": {
        "name": "Electrum Wallet (Salt-Type 1-3)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "4629.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "4628.3 MH/s" },
      "ratio": 0.99,
    },
  
    "16700": {
        "name": "FileVault 2",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "406.9 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "407.3 kH/s" },
      "ratio": 1,
    },
  
    "16900": {
        "name": "Ansible Vault",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "871.3 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "861.9 kH/s" },
      "ratio": 0.98,
    },
  
    "17010": {
        "name": "GPG (AES-128/AES-256 (SHA-1($pass)))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "26540.9 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "26538.7 kH/s" },
      "ratio": 0.99,
    },
  
    "17020": {
        "name": "GPG (AES-128/AES-256 (SHA-512($pass)))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "33525 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "32673 H/s" },
      "ratio": 0.97,
    },
  
    "17030": {
        "name": "GPG (AES-128/AES-256 (SHA-256($pass)))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "13233.0 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "13222.5 kH/s" },
      "ratio": 0.99,
    },
  
    "17040": {
        "name": "GPG (CAST5 (SHA-1($pass)))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "70810.7 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "70825.5 kH/s" },
      "ratio": 1,
    },
  
    "17200": {
        "name": "PKZIP (Compressed)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "1683.8 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "1637.7 MH/s" },
      "ratio": 0.97,
    },
  
    "17210": {
        "name": "PKZIP (Uncompressed)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "7350.3 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "7388.4 MH/s" },
      "ratio": 1,
    },
  
    "17220": {
        "name": "PKZIP (Compressed Multi-File)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "43344.3 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "43371.5 MH/s" },
      "ratio": 1,
    },
  
    "17225": {
        "name": "PKZIP (Mixed Multi-File)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "51664.3 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "52342.8 MH/s" },
      "ratio": 1.01,
    },
  
    "17230": {
        "name": "PKZIP (Mixed Multi-File Checksum-Only)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "59826.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "59691.6 MH/s" },
      "ratio": 0.99,
    },
  
    "17300": {
        "name": "SHA3-224",
      "regular": { "minLen": 0, "maxLen": 55, "speed": "4725.9 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "4737.7 MH/s" },
      "ratio": 1,
    },
  
    "17400": {
        "name": "SHA3-256",
      "regular": { "minLen": 0, "maxLen": 55, "speed": "4735.2 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "4733.9 MH/s" },
      "ratio": 0.99,
    },
  
    "17500": {
        "name": "SHA3-384",
      "regular": { "minLen": 0, "maxLen": 55, "speed": "4725.7 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "4724.7 MH/s" },
      "ratio": 0.99,
    },
  
    "17600": {
        "name": "SHA3-512",
      "regular": { "minLen": 0, "maxLen": 55, "speed": "4736.9 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "4719.7 MH/s" },
      "ratio": 0.99,
    },
  
    "17700": {
        "name": "Keccak-224",
      "regular": { "minLen": 0, "maxLen": 55, "speed": "4722.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "4726.8 MH/s" },
      "ratio": 1,
    },
  
    "17800": {
        "name": "Keccak-256",
      "regular": { "minLen": 0, "maxLen": 55, "speed": "4723.6 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "4718.6 MH/s" },
      "ratio": 0.99,
    },
  
    "17900": {
        "name": "Keccak-384",
      "regular": { "minLen": 0, "maxLen": 55, "speed": "4724.5 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "4720.7 MH/s" },
      "ratio": 0.99,
    },
  
    "18000": {
        "name": "Keccak-512",
      "regular": { "minLen": 0, "maxLen": 55, "speed": "4735.7 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "4720.0 MH/s" },
      "ratio": 0.99,
    },
  
    "18100": {
        "name": "TOTP (HMAC-SHA1)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "8865.5 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "8749.7 MH/s" },
      "ratio": 0.98,
    },
  
    "18200": {
        "name": "Kerberos 5, etype 23, AS-REP",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "2449.9 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "2727.8 MH/s" },
      "ratio": 1.11,
    },
  
    "18300": {
        "name": "Apple File System (APFS)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "434.6 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "434.6 kH/s" },
      "ratio": 1,
    },
  
    "18400": {
        "name": "Open Document Format (ODF) 1.2 (SHA-256, AES)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "102.3 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "102.1 kH/s" },
      "ratio": 0.99,
    },
  
    "18500": {
        "name": "sha1(md5(md5($pass)))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "18356.4 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "18355.5 MH/s" },
      "ratio": 0.99,
    },
  
    "18600": {
        "name": "Open Document Format (ODF) 1.1 (SHA-1, Blowfish)",
      "regular": { "minLen": 0, "maxLen": 51, "speed": "3424.1 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 51, "speed": "3424.7 kH/s" },
      "ratio": 1,
    },
  
    "18700": {
        "name": "Java Object hashCode()",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "502.5 GH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "948.0 GH/s" },
      "ratio": 1.88,
    },
  
    "18800": {
        "name": "Blockchain, My Wallet, Second Password (SHA256)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "1745.3 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "1754.1 kH/s" },
      "ratio": 1,
    },
  
    "18900": {
        "name": "Android Backup",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "1023.1 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "1023.2 kH/s" },
      "ratio": 1,
    },
  
    "19000": {
        "name": "QNX /etc/shadow (MD5)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "173.6 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "174.0 MH/s" },
      "ratio": 1,
    },
  
    "19100": {
        "name": "QNX /etc/shadow (SHA256)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "87188.9 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "87174.9 kH/s" },
      "ratio": 0.99,
    },
  
    "19200": {
        "name": "QNX /etc/shadow (SHA512)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "59557.8 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "59553.6 kH/s" },
      "ratio": 0.99,
    },
  
    "19210": {
        "name": "QNX 7 /etc/shadow (SHA512)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "767.4 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "767.3 kH/s" },
      "ratio": 0.99,
    },
  
    "19300": {
        "name": "sha1($salt1.$pass.$salt2)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "4721.8 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "4763.0 MH/s" },
      "ratio": 1,
    },
  
    "19500": {
        "name": "Ruby on Rails Restful-Authentication",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "347.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "347.1 MH/s" },
      "ratio": 1,
    },
  
    "19600": {
        "name": "Kerberos 5, etype 17, TGS-REP",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "4962.4 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "4890.3 kH/s" },
      "ratio": 0.98,
    },
  
    "19700": {
        "name": "Kerberos 5, etype 18, TGS-REP",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "2453.6 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "2480.0 kH/s" },
      "ratio": 1.01,
    },
  
    "19800": {
        "name": "Kerberos 5, etype 17, Pre-Auth",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "4985.1 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "4962.8 kH/s" },
      "ratio": 0.99,
    },
  
    "19900": {
        "name": "Kerberos 5, etype 18, Pre-Auth",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "2481.9 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "2481.9 kH/s" },
      "ratio": 1,
    },
  
    "20011": {
        "name": "DiskCryptor SHA512 + XTS 512 bit",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "2787.6 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "2788.5 kH/s" },
      "ratio": 1,
    },
  
    "20012": {
        "name": "DiskCryptor SHA512 + XTS 1024 bit",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "1290.2 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "1307.4 kH/s" },
      "ratio": 1.01,
    },
  
    "20013": {
        "name": "DiskCryptor SHA512 + XTS 1536 bit",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "854.7 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "838.7 kH/s" },
      "ratio": 0.98,
    },
  
    "20200": {
        "name": "Python passlib pbkdf2-sha512",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "126.3 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "126.1 kH/s" },
      "ratio": 0.99,
    },
  
    "20300": {
        "name": "Python passlib pbkdf2-sha256",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "281.2 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "281.2 kH/s" },
      "ratio": 1,
    },
  
    "20400": {
        "name": "Python passlib pbkdf2-sha1",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "156.2 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "156.1 kH/s" },
      "ratio": 0.99,
    },
  
    "20500": {
        "name": "PKZIP Master Key",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "534.5 GH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "533.8 GH/s" },
      "ratio": 0.99,
    },
  
    "20510": {
        "name": "PKZIP Master Key (6 byte optimization)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "105.6 GH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "105.6 GH/s" },
      "ratio": 1,
    },
  
    "20600": {
        "name": "Oracle Transportation Management (SHA256)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "17196.6 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "17180.0 kH/s" },
      "ratio": 0.99,
    },
  
    "20710": {
        "name": "sha256(sha256($pass).$salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "3746.0 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "5728.2 MH/s" },
      "ratio": 1.52,
    },
  
    "20711": {
        "name": "AuthMe sha256",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "3719.3 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "5734.8 MH/s" },
      "ratio": 1.54,
    },
  
    "20712": {
        "name": "RSA Security Analytics / NetWitness (sha256)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "3694.0 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "5728.1 MH/s" },
      "ratio": 1.55,
    },
  
    "20720": {
        "name": "sha256($salt.sha256($pass))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "5125.6 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "5097.5 MH/s" },
      "ratio": 0.99,
    },
  
    "20800": {
        "name": "sha256(md5($pass))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "13006.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "15945.9 MH/s" },
      "ratio": 1.22,
    },
  
    "20900": {
        "name": "md5(sha1($pass).md5($pass).sha1($pass))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "13554.9 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "15471.3 MH/s" },
      "ratio": 1.14,
    },
  
    "21000": {
        "name": "BitShares v0.x - sha512(sha512_bin(pass))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "2584.3 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "3043.7 MH/s" },
      "ratio": 1.17,
    },
  
    "21100": {
        "name": "sha1(md5($pass.$salt))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "22590.0 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "26921.8 MH/s" },
      "ratio": 1.19,
    },
  
    "21200": {
        "name": "md5(sha1($salt).md5($pass))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "26894.8 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "33969.8 MH/s" },
      "ratio": 1.26,
    },
  
    "21300": {
        "name": "md5($salt.sha1($salt.$pass))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "20180.3 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "20145.6 MH/s" },
      "ratio": 0.99,
    },
  
    "21310": {
        "name": "md5($salt1.sha1($salt2.$pass))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "20243.2 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "20224.9 MH/s" },
      "ratio": 0.99,
    },
  
    "21400": {
        "name": "sha256(sha256_bin($pass))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "7964.2 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "9470.5 MH/s" },
      "ratio": 1.18,
    },
  
    "21420": {
        "name": "sha256($salt.sha256_bin($pass))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "5262.4 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "5234.6 MH/s" },
      "ratio": 0.99,
    },
  
    "21500": {
        "name": "SolarWinds Orion",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "357.6 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "357.7 kH/s" },
      "ratio": 1,
    },
  
    "21501": {
        "name": "SolarWinds Orion v2",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "357.7 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "357.6 kH/s" },
      "ratio": 0.99,
    },
  
    "21600": {
        "name": "Web2py pbkdf2-sha512",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "3123.3 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "3123.3 kH/s" },
      "ratio": 1,
    },
  
    "21700": {
        "name": "Electrum Wallet (Salt-Type 4)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "2200.8 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "2200.7 kH/s" },
      "ratio": 0.99,
    },
  
    "21800": {
        "name": "Electrum Wallet (Salt-Type 5)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "2121.1 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "2120.8 kH/s" },
      "ratio": 0.99,
    },
  
    "22000": {
        "name": "WPA-PBKDF2-PMKID+EAPOL",
      "regular": { "minLen": 8, "maxLen": 63, "speed": "2494.5 kH/s" },
      "optimized": { "minLen": 8, "maxLen": 63, "speed": "2459.2 kH/s" },
      "ratio": 0.98,
    },
  
    "22001": {
        "name": "WPA-PMK-PMKID+EAPOL",
      "regular": { "minLen": 64, "maxLen": 64, "speed": "819.5 MH/s" },
      "optimized": { "minLen": 64, "maxLen": 64, "speed": "814.5 MH/s" },
      "ratio": 0.99,
    },
  
    "22100": {
        "name": "BitLocker",
      "regular": { "minLen": 4, "maxLen": 256, "speed": "85 H/s" },
      "optimized": { "minLen": 4, "maxLen": 256, "speed": "86 H/s" },
      "ratio": 1.01,
    },
  
    "22200": {
        "name": "Citrix NetScaler (SHA512)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "3196.0 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "6257.5 MH/s" },
      "ratio": 1.95,
    },
  
    "22300": {
        "name": "sha256($salt.$pass.$salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "5101.8 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "18069.1 MH/s" },
      "ratio": 3.54,
    },
  
    "22301": {
        "name": "Telegram Mobile App Passcode (SHA256)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "5074.8 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "18455.3 MH/s" },
      "ratio": 3.63,
    },
  
    "22400": {
        "name": "AES Crypt (SHA256)",
      "regular": { "minLen": 0, "maxLen": 128, "speed": "2034.8 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 128, "speed": "2046.3 kH/s" },
      "ratio": 1,
    },
  
    "22500": {
        "name": "MultiBit Classic .key (MD5)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "2014.2 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 31, "speed": "5104.3 MH/s" },
      "ratio": 2.53,
    },
  
    "22600": {
        "name": "Telegram Desktop < v2.1.14 (PBKDF2-HMAC-SHA1)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "726.9 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "725.6 kH/s" },
      "ratio": 0.99,
    },
  
    "22700": {
        "name": "MultiBit HD (scrypt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "9746 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "9744 H/s" },
      "ratio": 0.99,
    },
  
    "22911": {
        "name": "RSA/DSA/EC/OpenSSH Private Keys ($0$)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "4289.6 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "4294.8 MH/s" },
      "ratio": 1,
    },
  
    "22921": {
        "name": "RSA/DSA/EC/OpenSSH Private Keys ($6$)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "13993.0 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "13987.0 MH/s" },
      "ratio": 0.99,
    },
  
    "22931": {
        "name": "RSA/DSA/EC/OpenSSH Private Keys ($1, $3$)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "7101.3 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "7096.3 MH/s" },
      "ratio": 0.99,
    },
  
    "22941": {
        "name": "RSA/DSA/EC/OpenSSH Private Keys ($4$)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "5894.5 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "5899.9 MH/s" },
      "ratio": 1,
    },
  
    "22951": {
        "name": "RSA/DSA/EC/OpenSSH Private Keys ($5$)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "4735.3 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "4960.1 MH/s" },
      "ratio": 1.04,
    },
  
    "23001": {
        "name": "SecureZIP AES-128",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "6769.6 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "7004.2 MH/s" },
      "ratio": 1.03,
    },
  
    "23002": {
        "name": "SecureZIP AES-192",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "5051.5 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "5268.9 MH/s" },
      "ratio": 1.04,
    },
  
    "23003": {
        "name": "SecureZIP AES-256",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "4202.8 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "4527.5 MH/s" },
      "ratio": 1.07,
    },
  
    "23100": {
        "name": "Apple Keychain",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "10076.0 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "10004.5 kH/s" },
      "ratio": 0.99,
    },
  
    "23200": {
        "name": "XMPP SCRAM PBKDF2-SHA1",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "4972.2 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "4963.8 kH/s" },
      "ratio": 0.99,
    },
  
    "23300": {
        "name": "Apple iWork",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "5102.3 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "5107.6 kH/s" },
      "ratio": 1,
    },
  
    "23400": {
        "name": "Bitwarden",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "86921 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "87154 H/s" },
      "ratio": 1,
    },
  
    "23500": {
        "name": "AxCrypt 2 AES-128",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "460.4 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "460.5 kH/s" },
      "ratio": 1,
    },
  
    "23600": {
        "name": "AxCrypt 2 AES-256",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "238.7 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "238.7 kH/s" },
      "ratio": 1,
    },
  
    "23700": {
        "name": "RAR3-p (Uncompressed)",
      "regular": { "minLen": 0, "maxLen": 128, "speed": "202.1 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 20, "speed": "73575 H/s" },
      "ratio": 0.36,
    },
  
    "23800": {
        "name": "RAR3-p (Compressed)",
      "regular": { "minLen": 0, "maxLen": 128, "speed": "170.1 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 20, "speed": "60561 H/s" },
      "ratio": 0.35,
    },
  
    "23900": {
        "name": "BestCrypt v3 Volume Encryption",
      "regular": { "minLen": 0, "maxLen": 56, "speed": "12378.8 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 56, "speed": "12389.2 kH/s" },
      "ratio": 1,
    },
  
    "24100": {
        "name": "MongoDB ServerKey SCRAM-SHA-1",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "2052.0 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "2045.7 kH/s" },
      "ratio": 0.99,
    },
  
    "24200": {
        "name": "MongoDB ServerKey SCRAM-SHA-256",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "575.4 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "572.6 kH/s" },
      "ratio": 0.99,
    },
  
    "24300": {
        "name": "sha1($salt.sha1($pass.$salt))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "5595.5 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "18043.7 MH/s" },
      "ratio": 3.22,
    },
  
    "24410": {
        "name": "PKCS#8 Private Keys (PBKDF2-HMAC-SHA1 + 3DES/AES)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "4933.2 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "4932.3 kH/s" },
      "ratio": 0.99,
    },
  
    "24420": {
        "name": "PKCS#8 Private Keys (PBKDF2-HMAC-SHA256 + 3DES/AES)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "3926.0 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "3954.4 kH/s" },
      "ratio": 1,
    },
  
    "24500": {
        "name": "Telegram Desktop >= v2.1.14 (PBKDF2-HMAC-SHA512)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "10372 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "10360 H/s" },
      "ratio": 0.99,
    },
  
    "24600": {
        "name": "SQLCipher",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "159.9 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "160.1 kH/s" },
      "ratio": 1,
    },
  
    "24700": {
        "name": "Stuffit5",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "41089.5 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "54355.4 MH/s" },
      "ratio": 1.32,
    },
  
    "24800": {
        "name": "Umbraco HMAC-SHA1",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "4537.7 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 27, "speed": "10260.5 MH/s" },
      "ratio": 2.26,
    },
  
    "24900": {
        "name": "Dahua Authentication MD5",
      "regular": { "minLen": 0, "maxLen": 55, "speed": "81024.2 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "81062.7 MH/s" },
      "ratio": 1,
    },
  
    "25000": {
        "name": "SNMPv3 HMAC-MD5-96/HMAC-SHA1-96",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "1313.4 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "1311.0 kH/s" },
      "ratio": 0.99,
    },
  
    "25100": {
        "name": "SNMPv3 HMAC-MD5-96",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "3836.5 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "3842.7 kH/s" },
      "ratio": 1,
    },
  
    "25200": {
        "name": "SNMPv3 HMAC-SHA1-96",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "2057.2 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "2052.6 kH/s" },
      "ratio": 0.99,
    },
  
    "25300": {
        "name": "MS Office 2016 - SheetProtection",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "66653 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "66647 H/s" },
      "ratio": 0.99,
    },
  
    "25400": {
        "name": "PDF 1.4 - 1.6 (Acrobat 5 - 8) - user and owner pass",
      "regular": { "minLen": 0, "maxLen": 32, "speed": "84014.6 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 32, "speed": "84471.7 kH/s" },
      "ratio": 1,
    },
  
    "25500": {
        "name": "Stargazer Stellar Wallet XLM",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "1969.2 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "1971.2 kH/s" },
      "ratio": 1,
    },
  
    "25600": {
        "name": "bcrypt(md5($pass)) / bcryptmd5",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "219.9 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "219.9 kH/s" },
      "ratio": 1,
    },
  
    "25700": {
        "name": "MurmurHash",
      "regular": { "minLen": 0, "maxLen": 55, "speed": "402.2 GH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "403.6 GH/s" },
      "ratio": 1,
    },
  
    "25800": {
        "name": "bcrypt(sha1($pass)) / bcryptsha1",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "219.9 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "219.9 kH/s" },
      "ratio": 1,
    },
  
    "25900": {
        "name": "KNX IP Secure - Device Authentication Code",
      "regular": { "minLen": 0, "maxLen": 20, "speed": "133.1 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 20, "speed": "132.7 kH/s" },
      "ratio": 0.99,
    },
  
    "26000": {
        "name": "Mozilla key3.db",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "1099.0 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "1190.2 MH/s" },
      "ratio": 1.08,
    },
  
    "26100": {
        "name": "Mozilla key4.db",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "811.9 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "810.4 kH/s" },
      "ratio": 0.99,
    },
  
    "26200": {
        "name": "OpenEdge Progress Encode",
      "regular": { "minLen": 0, "maxLen": 16, "speed": "244.8 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 16, "speed": "252.4 MH/s" },
      "ratio": 1.03,
    },
  
    "26300": {
        "name": "FortiGate256 (FortiOS256)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "14925.9 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "14931.2 MH/s" },
      "ratio": 1,
    },
  
    "26401": {
        "name": "AES-128-ECB NOKDF (PT = $salt, key = $pass)",
      "regular": { "minLen": 0, "maxLen": 16, "speed": "20748.2 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 16, "speed": "20785.3 MH/s" },
      "ratio": 1,
    },
  
    "26402": {
        "name": "AES-192-ECB NOKDF (PT = $salt, key = $pass)",
      "regular": { "minLen": 0, "maxLen": 24, "speed": "17811.5 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 24, "speed": "17834.9 MH/s" },
      "ratio": 1,
    },
  
    "26403": {
        "name": "AES-256-ECB NOKDF (PT = $salt, key = $pass)",
      "regular": { "minLen": 0, "maxLen": 32, "speed": "14752.8 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 32, "speed": "14621.2 MH/s" },
      "ratio": 0.99,
    },
  
    "26500": {
        "name": "iPhone passcode (UID key + System Keybag)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "220.2 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "220.4 kH/s" },
      "ratio": 1,
    },
  
    "26600": {
        "name": "MetaMask Wallet (needs all data, checks AES-GCM tag)",
      "regular": { "minLen": 8, "maxLen": 256, "speed": "807.0 kH/s" },
      "optimized": { "minLen": 8, "maxLen": 256, "speed": "812.3 kH/s" },
      "ratio": 1,
    },
  
    "26610": {
        "name": "MetaMask Wallet (short hash, plaintext check)",
      "regular": { "minLen": 8, "maxLen": 256, "speed": "811.3 kH/s" },
      "optimized": { "minLen": 8, "maxLen": 256, "speed": "808.9 kH/s" },
      "ratio": 0.99,
    },
  
    "26700": {
        "name": "SNMPv3 HMAC-SHA224-128",
      "regular": { "minLen": 8, "maxLen": 256, "speed": "936.4 kH/s" },
      "optimized": { "minLen": 8, "maxLen": 256, "speed": "955.8 kH/s" },
      "ratio": 1.02,
    },
  
    "26800": {
        "name": "SNMPv3 HMAC-SHA256-192",
      "regular": { "minLen": 8, "maxLen": 256, "speed": "955.9 kH/s" },
      "optimized": { "minLen": 8, "maxLen": 256, "speed": "955.7 kH/s" },
      "ratio": 0.99,
    },
  
    "27000": {
        "name": "NetNTLMv1 / NetNTLMv1+ESS (NT)",
      "regular": { "minLen": 32, "maxLen": 32, "speed": "954.1 MH/s" },
      "optimized": { "minLen": 32, "maxLen": 32, "speed": "954.0 MH/s" },
      "ratio": 0.99,
    },
  
    "27100": {
        "name": "NetNTLMv2 (NT)",
      "regular": { "minLen": 32, "maxLen": 32, "speed": "892.9 MH/s" },
      "optimized": { "minLen": 32, "maxLen": 32, "speed": "892.6 MH/s" },
      "ratio": 0.99,
    },
  
    "27200": {
        "name": "Ruby on Rails Restful Auth (one round, no sitekey)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "7278.6 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 9, "speed": "43804.6 MH/s" },
      "ratio": 6.01,
    },
  
    "27400": {
        "name": "VMware VMX (PBKDF2-HMAC-SHA1 + AES-256-CBC)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "1019.2 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "1018.4 kH/s" },
      "ratio": 0.99,
    },
  
    "27500": {
        "name": "VirtualBox (PBKDF2-HMAC-SHA256 & AES-128-XTS)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "29146 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "29113 H/s" },
      "ratio": 0.99,
    },
  
    "27600": {
        "name": "VirtualBox (PBKDF2-HMAC-SHA256 & AES-256-XTS)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "23983 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "23986 H/s" },
      "ratio": 1,
    },
  
    "27700": {
        "name": "MultiBit Classic .wallet (scrypt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "9746 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "9750 H/s" },
      "ratio": 1,
    },
  
    "27800": {
        "name": "MurmurHash3",
      "regular": { "minLen": 0, "maxLen": 55, "speed": "285.4 GH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "284.9 GH/s" },
      "ratio": 0.99,
    },
  
    "27900": {
        "name": "CRC32C",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "40097.5 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "39108.3 MH/s" },
      "ratio": 0.97,
    },
  
    "28000": {
        "name": "CRC64Jones",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "270.8 GH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "225.6 GH/s" },
      "ratio": 0.83,
    },
  
    "28100": {
        "name": "Windows Hello PIN/Password",
      "regular": { "minLen": 4, "maxLen": 127, "speed": "809.1 kH/s" },
      "optimized": { "minLen": 4, "maxLen": 127, "speed": "804.7 kH/s" },
      "ratio": 0.99,
    },
  
    "28200": {
        "name": "Exodus Desktop Wallet (scrypt)",
      "regular": { "minLen": 4, "maxLen": 256, "speed": "9587 H/s" },
      "optimized": { "minLen": 4, "maxLen": 256, "speed": "9573 H/s" },
      "ratio": 0.99,
    },
  
    "28300": {
        "name": "Teamspeak 3 (channel hash)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "8120.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "8128.4 MH/s" },
      "ratio": 1,
    },
  
    "28400": {
        "name": "bcrypt(sha512($pass)) / bcryptsha512",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "1772 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "1780 H/s" },
      "ratio": 1,
    },
  
    "28501": {
        "name": "Bitcoin WIF private key (P2PKH), compressed",
      "regular": { "minLen": 52, "maxLen": 52, "speed": "9568.5 GH/s" },
      "optimized": { "minLen": 52, "maxLen": 52, "speed": "9582.1 GH/s" },
      "ratio": 1,
    },
  
    "28502": {
        "name": "Bitcoin WIF private key (P2PKH), uncompressed",
      "regular": { "minLen": 51, "maxLen": 51, "speed": "5827.8 GH/s" },
      "optimized": { "minLen": 51, "maxLen": 51, "speed": "5826.0 GH/s" },
      "ratio": 0.99,
    },
  
    "28503": {
        "name": "Bitcoin WIF private key (P2WPKH, Bech32), compressed",
      "regular": { "minLen": 52, "maxLen": 52, "speed": "9550.6 GH/s" },
      "optimized": { "minLen": 52, "maxLen": 52, "speed": "9631.0 GH/s" },
      "ratio": 1,
    },
  
    "28504": {
        "name": "Bitcoin WIF private key (P2WPKH, Bech32), uncompressed",
      "regular": { "minLen": 51, "maxLen": 51, "speed": "5861.2 GH/s" },
      "optimized": { "minLen": 51, "maxLen": 51, "speed": "5866.5 GH/s" },
      "ratio": 1,
    },
  
    "28505": {
        "name": "Bitcoin WIF private key (P2SH(P2WPKH)), compressed",
      "regular": { "minLen": 52, "maxLen": 52, "speed": "9579.9 GH/s" },
      "optimized": { "minLen": 52, "maxLen": 52, "speed": "9601.6 GH/s" },
      "ratio": 1,
    },
  
    "28506": {
        "name": "Bitcoin WIF private key (P2SH(P2WPKH)), uncompressed",
      "regular": { "minLen": 51, "maxLen": 51, "speed": "2980.0 GH/s" },
      "optimized": { "minLen": 51, "maxLen": 51, "speed": "5944.9 GH/s" },
      "ratio": 1.99,
    },
  
    "28600": {
        "name": "PostgreSQL SCRAM-SHA-256",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "2125.9 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "2125.1 kH/s" },
      "ratio": 0.99,
    },
  
    "28700": {
        "name": "Amazon AWS4-HMAC-SHA256",
      "regular": { "minLen": 0, "maxLen": 252, "speed": "399.4 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 51, "speed": "660.9 MH/s" },
      "ratio": 1.65,
    },
  
    "28800": {
        "name": "Kerberos 5, etype 17, DB",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "4984.7 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "4977.5 kH/s" },
      "ratio": 0.99,
    },
  
    "28900": {
        "name": "Kerberos 5, etype 18, DB",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "2485.0 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "2485.2 kH/s" },
      "ratio": 1,
    },
  
    "29000": {
        "name": "sha1($salt.sha1(utf16le($username).':'.utf16le($pass)))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "16141.5 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "16152.7 MH/s" },
      "ratio": 1,
    },
  
    "29100": {
        "name": "Flask Session Cookie ($salt.$salt.$pass)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "2603.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "2632.9 MH/s" },
      "ratio": 1.01,
    },
  
    "29200": {
        "name": "Radmin3",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "3444.0 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "3445.5 kH/s" },
      "ratio": 1,
    },
  
    "29311": {
        "name": "TrueCrypt RIPEMD160 + XTS 512 bit",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "1861.4 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "1861.4 kH/s" },
      "ratio": 1,
    },
  
    "29312": {
        "name": "TrueCrypt RIPEMD160 + XTS 1024 bit",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "1008.9 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "1007.0 kH/s" },
      "ratio": 0.99,
    },
  
    "29313": {
        "name": "TrueCrypt RIPEMD160 + XTS 1536 bit",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "677.3 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "690.7 kH/s" },
      "ratio": 1.01,
    },
  
    "29321": {
        "name": "TrueCrypt SHA512 + XTS 512 bit",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "2760.4 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "2788.7 kH/s" },
      "ratio": 1.01,
    },
  
    "29322": {
        "name": "TrueCrypt SHA512 + XTS 1024 bit",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "1263.6 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "1304.8 kH/s" },
      "ratio": 1.03,
    },
  
    "29323": {
        "name": "TrueCrypt SHA512 + XTS 1536 bit",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "826.5 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "853.5 kH/s" },
      "ratio": 1.03,
    },
  
    "29331": {
        "name": "TrueCrypt Whirlpool + XTS 512 bit",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "380.4 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "380.1 kH/s" },
      "ratio": 0.99,
    },
  
    "29332": {
        "name": "TrueCrypt Whirlpool + XTS 1024 bit",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "185.9 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "188.4 kH/s" },
      "ratio": 1.01,
    },
  
    "29333": {
        "name": "TrueCrypt Whirlpool + XTS 1536 bit",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "124.6 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "124.6 kH/s" },
      "ratio": 1,
    },
  
    "29341": {
        "name": "TrueCrypt RIPEMD160 + XTS 512 bit + boot-mode",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "3432.8 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "3433.2 kH/s" },
      "ratio": 1,
    },
  
    "29342": {
        "name": "TrueCrypt RIPEMD160 + XTS 1024 bit + boot-mode",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "1774.2 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "1774.1 kH/s" },
      "ratio": 0.99,
    },
  
    "29343": {
        "name": "TrueCrypt RIPEMD160 + XTS 1536 bit + boot-mode",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "1198.6 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "1198.7 kH/s" },
      "ratio": 1,
    },
  
    "29411": {
        "name": "VeraCrypt RIPEMD160 + XTS 512 bit",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "6131 H/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "6126 H/s" },
      "ratio": 0.99,
    },
  
    "29412": {
        "name": "VeraCrypt RIPEMD160 + XTS 1024 bit",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "3487 H/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "3488 H/s" },
      "ratio": 1,
    },
  
    "29413": {
        "name": "VeraCrypt RIPEMD160 + XTS 1536 bit",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "2445 H/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "2446 H/s" },
      "ratio": 1,
    },
  
    "29421": {
        "name": "VeraCrypt SHA512 + XTS 512 bit",
      "regular": { "minLen": 0, "maxLen": 128, "speed": "6128 H/s" },
      "optimized": { "minLen": 0, "maxLen": 128, "speed": "6131 H/s" },
      "ratio": 1,
    },
  
    "29422": {
        "name": "VeraCrypt SHA512 + XTS 1024 bit",
      "regular": { "minLen": 0, "maxLen": 128, "speed": "3059 H/s" },
      "optimized": { "minLen": 0, "maxLen": 128, "speed": "3059 H/s" },
      "ratio": 1,
    },
  
    "29423": {
        "name": "VeraCrypt SHA512 + XTS 1536 bit",
      "regular": { "minLen": 0, "maxLen": 128, "speed": "2038 H/s" },
      "optimized": { "minLen": 0, "maxLen": 128, "speed": "2037 H/s" },
      "ratio": 0.99,
    },
  
    "29431": {
        "name": "VeraCrypt Whirlpool + XTS 512 bit",
      "regular": { "minLen": 0, "maxLen": 128, "speed": "756 H/s" },
      "optimized": { "minLen": 0, "maxLen": 128, "speed": "755 H/s" },
      "ratio": 0.99,
    },
  
    "29432": {
        "name": "VeraCrypt Whirlpool + XTS 1024 bit",
      "regular": { "minLen": 0, "maxLen": 128, "speed": "375 H/s" },
      "optimized": { "minLen": 0, "maxLen": 128, "speed": "375 H/s" },
      "ratio": 1,
    },
  
    "29433": {
        "name": "VeraCrypt Whirlpool + XTS 1536 bit",
      "regular": { "minLen": 0, "maxLen": 128, "speed": "249 H/s" },
      "optimized": { "minLen": 0, "maxLen": 128, "speed": "249 H/s" },
      "ratio": 1,
    },
  
    "29441": {
        "name": "VeraCrypt RIPEMD160 + XTS 512 bit + boot-mode",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "12267 H/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "12265 H/s" },
      "ratio": 0.99,
    },
  
    "29442": {
        "name": "VeraCrypt RIPEMD160 + XTS 1024 bit + boot-mode",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "6983 H/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "6982 H/s" },
      "ratio": 0.99,
    },
  
    "29443": {
        "name": "VeraCrypt RIPEMD160 + XTS 1536 bit + boot-mode",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "4885 H/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "4897 H/s" },
      "ratio": 1,
    },
  
    "29451": {
        "name": "VeraCrypt SHA256 + XTS 512 bit",
      "regular": { "minLen": 0, "maxLen": 128, "speed": "8558 H/s" },
      "optimized": { "minLen": 0, "maxLen": 128, "speed": "8557 H/s" },
      "ratio": 0.99,
    },
  
    "29452": {
        "name": "VeraCrypt SHA256 + XTS 1024 bit",
      "regular": { "minLen": 0, "maxLen": 128, "speed": "4279 H/s" },
      "optimized": { "minLen": 0, "maxLen": 128, "speed": "4277 H/s" },
      "ratio": 0.99,
    },
  
    "29453": {
        "name": "VeraCrypt SHA256 + XTS 1536 bit",
      "regular": { "minLen": 0, "maxLen": 128, "speed": "2851 H/s" },
      "optimized": { "minLen": 0, "maxLen": 128, "speed": "2862 H/s" },
      "ratio": 1,
    },
  
    "29461": {
        "name": "VeraCrypt SHA256 + XTS 512 bit + boot-mode",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "21357 H/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "21375 H/s" },
      "ratio": 1,
    },
  
    "29462": {
        "name": "VeraCrypt SHA256 + XTS 1024 bit + boot-mode",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "10670 H/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "10673 H/s" },
      "ratio": 1,
    },
  
    "29463": {
        "name": "VeraCrypt SHA256 + XTS 1536 bit + boot-mode",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "7121 H/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "7117 H/s" },
      "ratio": 0.99,
    },
  
    "29471": {
        "name": "VeraCrypt Streebog-512 + XTS 512 bit",
      "regular": { "minLen": 0, "maxLen": 128, "speed": "297 H/s" },
      "optimized": { "minLen": 0, "maxLen": 128, "speed": "296 H/s" },
      "ratio": 0.99,
    },
  
    "29472": {
        "name": "VeraCrypt Streebog-512 + XTS 1024 bit",
      "regular": { "minLen": 0, "maxLen": 128, "speed": "148 H/s" },
      "optimized": { "minLen": 0, "maxLen": 128, "speed": "148 H/s" },
      "ratio": 1,
    },
  
    "29473": {
        "name": "VeraCrypt Streebog-512 + XTS 1536 bit",
      "regular": { "minLen": 0, "maxLen": 128, "speed": "99 H/s" },
      "optimized": { "minLen": 0, "maxLen": 128, "speed": "99 H/s" },
      "ratio": 1,
    },
  
    "29481": {
        "name": "VeraCrypt Streebog-512 + XTS 512 bit + boot-mode",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "741 H/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "741 H/s" },
      "ratio": 1,
    },
  
    "29482": {
        "name": "VeraCrypt Streebog-512 + XTS 1024 bit + boot-mode",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "370 H/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "371 H/s" },
      "ratio": 1,
    },
  
    "29483": {
        "name": "VeraCrypt Streebog-512 + XTS 1536 bit + boot-mode",
      "regular": { "minLen": 0, "maxLen": 64, "speed": "248 H/s" },
      "optimized": { "minLen": 0, "maxLen": 64, "speed": "247 H/s" },
      "ratio": 0.99,
    },
  
    "29511": {
        "name": "LUKS v1 SHA-1 + AES",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "130.1 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "129.6 kH/s" },
      "ratio": 0.99,
    },
  
    "29512": {
        "name": "LUKS v1 SHA-1 + Serpent",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "63869 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "63700 H/s" },
      "ratio": 0.99,
    },
  
    "29513": {
        "name": "LUKS v1 SHA-1 + Twofish",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "12895 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "12936 H/s" },
      "ratio": 1,
    },
  
    "29521": {
        "name": "LUKS v1 SHA-256 + AES",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "85470 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "85492 H/s" },
      "ratio": 1,
    },
  
    "29522": {
        "name": "LUKS v1 SHA-256 + Serpent",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "41393 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "41408 H/s" },
      "ratio": 1,
    },
  
    "29523": {
        "name": "LUKS v1 SHA-256 + Twofish",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "10846 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "10839 H/s" },
      "ratio": 0.99,
    },
  
    "29531": {
        "name": "LUKS v1 SHA-512 + AES",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "41687 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "41675 H/s" },
      "ratio": 0.99,
    },
  
    "29532": {
        "name": "LUKS v1 SHA-512 + Serpent",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "41877 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "41812 H/s" },
      "ratio": 0.99,
    },
  
    "29533": {
        "name": "LUKS v1 SHA-512 + Twofish",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "9843 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "9830 H/s" },
      "ratio": 0.99,
    },
  
    "29541": {
        "name": "LUKS v1 RIPEMD-160 + AES",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "73051 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "72585 H/s" },
      "ratio": 0.99,
    },
  
    "29542": {
        "name": "LUKS v1 RIPEMD-160 + Serpent",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "71997 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "71628 H/s" },
      "ratio": 0.99,
    },
  
    "29543": {
        "name": "LUKS v1 RIPEMD-160 + Twofish",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "26653 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "26748 H/s" },
      "ratio": 1,
    },
  
    "29600": {
        "name": "Terra Station Wallet (AES256-CBC(PBKDF2($pass)))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "86753.1 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "88593.0 kH/s" },
      "ratio": 1.02,
    },
  
    "29700": {
        "name": "KeePass 1 (AES/Twofish) and KeePass 2 (AES) - keyfile only mode",
      "regular": { "minLen": 32, "maxLen": 32, "speed": "131.5 kH/s" },
      "optimized": { "minLen": 32, "maxLen": 32, "speed": "131.3 kH/s" },
      "ratio": 0.99,
    },
  
    "29800": {
        "name": "Bisq .wallet (scrypt)",
      "regular": { "minLen": 8, "maxLen": 256, "speed": "1194 H/s" },
      "optimized": { "minLen": 8, "maxLen": 256, "speed": "1088 H/s" },
      "ratio": 0.91,
    },
  
    "29910": {
        "name": "ENCsecurity Datavault (PBKDF2/no keychain)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "80912 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "80932 H/s" },
      "ratio": 1,
    },
  
    "29920": {
        "name": "ENCsecurity Datavault (PBKDF2/keychain)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "20156 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "20153 H/s" },
      "ratio": 0.99,
    },
  
    "29930": {
        "name": "ENCsecurity Datavault (MD5/no keychain)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "92030.5 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "90440.0 kH/s" },
      "ratio": 0.98,
    },
  
    "29940": {
        "name": "ENCsecurity Datavault (MD5/keychain)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "72921.7 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "72891.9 kH/s" },
      "ratio": 0.99,
    },
  
    "30000": {
        "name": "Python Werkzeug MD5 (HMAC-MD5 (key = $salt))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "40420.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "52274.6 MH/s" },
      "ratio": 1.29,
    },
  
    "30120": {
        "name": "Python Werkzeug SHA256 (HMAC-SHA256 (key = $salt))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "4685.7 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "8979.4 MH/s" },
      "ratio": 1.91,
    },
  
    "30420": {
        "name": "DANE RFC7929/RFC8162 SHA2-256",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "15648.4 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "18868.7 MH/s" },
      "ratio": 1.2,
    },
  
    "30500": {
        "name": "md5(md5($salt).md5(md5($pass)))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "20852.9 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "23969.8 MH/s" },
      "ratio": 1.14,
    },
  
    "30600": {
        "name": "bcrypt(sha256($pass)) / bcryptsha256",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "7081 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "7113 H/s" },
      "ratio": 1,
    },
  
    "30700": {
        "name": "Anope IRC Services (enc_sha256)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "15682.2 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "21278.0 MH/s" },
      "ratio": 1.35,
    },
  
    "30901": {
        "name": "Bitcoin raw private key (P2PKH), compressed",
      "regular": { "minLen": 64, "maxLen": 64, "speed": "103.5 GH/s" },
      "optimized": { "minLen": 64, "maxLen": 64, "speed": "103.3 GH/s" },
      "ratio": 0.99,
    },
  
    "30902": {
        "name": "Bitcoin raw private key (P2PKH), uncompressed",
      "regular": { "minLen": 64, "maxLen": 64, "speed": "103.2 GH/s" },
      "optimized": { "minLen": 64, "maxLen": 64, "speed": "103.4 GH/s" },
      "ratio": 1,
    },
  
    "30903": {
        "name": "Bitcoin raw private key (P2WPKH, Bech32), compressed",
      "regular": { "minLen": 64, "maxLen": 64, "speed": "103.2 GH/s" },
      "optimized": { "minLen": 64, "maxLen": 64, "speed": "103.2 GH/s" },
      "ratio": 1,
    },
  
    "30904": {
        "name": "Bitcoin raw private key (P2WPKH, Bech32), uncompressed",
      "regular": { "minLen": 64, "maxLen": 64, "speed": "103.4 GH/s" },
      "optimized": { "minLen": 64, "maxLen": 64, "speed": "103.0 GH/s" },
      "ratio": 0.99,
    },
  
    "30905": {
        "name": "Bitcoin raw private key (P2SH(P2WPKH)), compressed",
      "regular": { "minLen": 64, "maxLen": 64, "speed": "103.0 GH/s" },
      "optimized": { "minLen": 64, "maxLen": 64, "speed": "103.1 GH/s" },
      "ratio": 1,
    },
  
    "30906": {
        "name": "Bitcoin raw private key (P2SH(P2WPKH)), uncompressed",
      "regular": { "minLen": 64, "maxLen": 64, "speed": "103.3 GH/s" },
      "optimized": { "minLen": 64, "maxLen": 64, "speed": "103.2 GH/s" },
      "ratio": 0.99,
    },
  
    "31000": {
        "name": "BLAKE2s-256",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "25869.1 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "30840.5 MH/s" },
      "ratio": 1.19,
    },
  
    "31100": {
        "name": "SM3",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "8331.7 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "19872.5 MH/s" },
      "ratio": 2.38,
    },
  
    "31200": {
        "name": "Veeam VBK",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "672.7 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "679.5 kH/s" },
      "ratio": 1.01,
    },
  
    "31300": {
        "name": "MS SNTP",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "22581.8 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 27, "speed": "37995.3 MH/s" },
      "ratio": 1.68,
    },
  
    "31400": {
        "name": "SecureCRT MasterPassphrase v2",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "9359.7 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "10117.5 MH/s" },
      "ratio": 1.08,
    },
  
    "31500": {
        "name": "Domain Cached Credentials (DCC), MS Cache (NT)",
      "regular": { "minLen": 32, "maxLen": 32, "speed": "855.4 MH/s" },
      "optimized": { "minLen": 32, "maxLen": 32, "speed": "855.5 MH/s" },
      "ratio": 1,
    },
  
    "31600": {
        "name": "Domain Cached Credentials 2 (DCC2), MS Cache 2, (NT)",
      "regular": { "minLen": 32, "maxLen": 32, "speed": "2003.8 kH/s" },
      "optimized": { "minLen": 32, "maxLen": 32, "speed": "2006.7 kH/s" },
      "ratio": 1,
    },
  
    "31700": {
        "name": "md5(md5(md5($pass).$salt1).$salt2)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "20381.9 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "20369.4 MH/s" },
      "ratio": 0.99,
    },
  
    "31800": {
        "name": "1Password, mobilekeychain (1Password 8)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "81599 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "81593 H/s" },
      "ratio": 0.99,
    },
  
    "31900": {
        "name": "MetaMask Mobile Wallet",
      "regular": { "minLen": 8, "maxLen": 256, "speed": "629.3 kH/s" },
      "optimized": { "minLen": 8, "maxLen": 256, "speed": "630.6 kH/s" },
      "ratio": 1,
    },
  
    "32000": {
        "name": "NetIQ SSPR (MD5)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "1039.5 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "1034.5 kH/s" },
      "ratio": 0.99,
    },
  
    "32010": {
        "name": "NetIQ SSPR (SHA1)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "411.3 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "411.5 kH/s" },
      "ratio": 1,
    },
  
    "32020": {
        "name": "NetIQ SSPR (SHA-1 with Salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "411.4 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "411.3 kH/s" },
      "ratio": 0.99,
    },
  
    "32030": {
        "name": "NetIQ SSPR (SHA-256 with Salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "163.1 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "163.1 kH/s" },
      "ratio": 1,
    },
  
    "32031": {
        "name": "Adobe AEM (SSPR, SHA-256 with Salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "16033.5 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "16027.1 kH/s" },
      "ratio": 0.99,
    },
  
    "32040": {
        "name": "NetIQ SSPR (SHA-512 with Salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "62289 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "62373 H/s" },
      "ratio": 1,
    },
  
    "32041": {
        "name": "Adobe AEM (SSPR, SHA-512 with Salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "6139.2 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "6189.3 kH/s" },
      "ratio": 1,
    },
  
    "32050": {
        "name": "NetIQ SSPR (PBKDF2WithHmacSHA1)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "205.1 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "204.7 kH/s" },
      "ratio": 0.99,
    },
  
    "32060": {
        "name": "NetIQ SSPR (PBKDF2WithHmacSHA256)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "87348 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "87092 H/s" },
      "ratio": 0.99,
    },
  
    "32070": {
        "name": "NetIQ SSPR (PBKDF2WithHmacSHA512)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "31402 H/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "31604 H/s" },
      "ratio": 1,
    },
  
    "32100": {
        "name": "Kerberos 5, etype 17, AS-REP",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "4978.0 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "4887.2 kH/s" },
      "ratio": 0.98,
    },
  
    "32200": {
        "name": "Kerberos 5, etype 18, AS-REP",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "2454.7 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "2479.8 kH/s" },
      "ratio": 1.01,
    },
  
    "32300": {
        "name": "Empire CMS (Admin password)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "10769.6 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "12001.4 MH/s" },
      "ratio": 1.11,
    },
  
    "32410": {
        "name": "sha512(sha512($pass).$salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "1096.0 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "1099.7 MH/s" },
      "ratio": 1,
    },
  
    "32420": {
        "name": "sha512(sha512_bin($pass).$salt)",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "1276.5 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "1233.4 MH/s" },
      "ratio": 0.96,
    },
  
    "32500": {
        "name": "Dogechain.info Wallet",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "1613.2 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "1624.3 kH/s" },
      "ratio": 1,
    },
  
    "32600": {
        "name": "CubeCart (whirlpool($salt.$pass.$salt))",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "2423.9 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "2424.5 MH/s" },
      "ratio": 1,
    },
  
    "32700": {
        "name": "Kremlin Encrypt 3.0 w/NewDES",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "2590.5 kH/s" },
      "optimized": { "minLen": 0, "maxLen": 256, "speed": "2599.3 kH/s" },
      "ratio": 1,
    },
  
    "99999": {
        "name": "Plaintext",
      "regular": { "minLen": 0, "maxLen": 256, "speed": "89753.8 MH/s" },
      "optimized": { "minLen": 0, "maxLen": 55, "speed": "280.4 GH/s" },
      "ratio": 3.12,
    }
  }
  